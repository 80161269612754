import { IncludedData } from '../../includedData';
import { ObjectData } from '../../objectData';
import { RelationshipData } from '../../relationshipData';
import { UploadRelationAttribute } from '../uploadRelationAttribute';
import { UserRelationAttribute } from '../userRelationAttribute';
import { CommentRelationships } from './commentRelationships';
import { CommentAttributes } from './commentAttributes';
import { EmailParticipantAttribute } from '../emailParticipantAttribute';

export class Comment extends ObjectData<CommentAttributes, CommentRelationships>{

  constructor(data: ObjectData<CommentAttributes, CommentRelationships>, included: Object[]) {
    super(data, included);
    if(included){
      this.relationships = Comment.normalizeRelationships(data.relationships, included);
    }
  }

  private static normalizeRelationships(relationObject:any, included: any): CommentRelationships
  {
    const relationships: CommentRelationships = {};

    relationships.author = Comment.normalizeUserRelation(relationObject, included, 'author');
    relationships.voters = Comment.normalizeUsersRelation(relationObject, included, 'voters');
    relationships.participants = Comment.normalizeUsersRelation(relationObject, included, 'participants');
    relationships.comment_participants = Comment.normalizeCommentParticipants(relationObject, included, 'comment_participants');
    relationships.uploads = Comment.normalizeUploadRelation(relationObject, included);

    return relationships;
  }

  private static normalizeUploadRelation(relationObject: any, included: Object[]): RelationshipData<UploadRelationAttribute>[] | undefined {
    if (!(relationObject?.uploads?.data?.length > 0)) return undefined;

    return relationObject.uploads.data.map((upload: any) => {
      let uploadRelation = new RelationshipData<UploadRelationAttribute>();

      uploadRelation.id = upload.id;
      uploadRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == uploadRelation.type &&
          includedObject.id == uploadRelation.id
      });
      uploadRelation.attributes = (tmp as IncludedData<UploadRelationAttribute>).attributes
      return uploadRelation;
    })
  }

  private static normalizeUsersRelation(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttribute>[] | undefined {
    if (!(relationObject[key]?.data?.length > 0)) return undefined;

    return relationObject[key].data.map((upload: any) => {
      let userRelation = new RelationshipData<UserRelationAttribute>();

      userRelation.id = upload.id;
      userRelation.type = upload.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == userRelation.type &&
          includedObject.id == userRelation.id
      });
      userRelation.attributes = (tmp as IncludedData<UserRelationAttribute>).attributes
      return userRelation;
    })
  }

  private static normalizeCommentParticipants(relationObject: any, included: Object[], key: string): RelationshipData<EmailParticipantAttribute>[] | undefined {
    if (!(relationObject[key]?.data?.length > 0)) return undefined;

    return relationObject[key].data.map((commentParticipant: any) => {
      let commentParticipantRelation = new RelationshipData<EmailParticipantAttribute>();

      commentParticipantRelation.id = commentParticipant.id;
      commentParticipantRelation.type = commentParticipant.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == commentParticipantRelation.type &&
          includedObject.id == commentParticipantRelation.id
      });
      commentParticipantRelation.attributes = (tmp as IncludedData<EmailParticipantAttribute>).attributes;

      return commentParticipantRelation;
    }).filter((f:any) => f.attributes.email != null); // return only when email is present
  }
}
