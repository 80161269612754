import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ReportParam} from "../utilities/models/parameters/reportParam/reportParam";
import {SortParam} from "../utilities/models/parameters/sortParam/sortParam";

@Injectable({ providedIn: "root" })
export class IncidentsService {
  constructor(private http: HttpClient) { }

  getIncidents(perPage: number = 20, page: number = 1, reportParams: ReportParam[], sort: SortParam) {
    const url = environment.api_url + 'operator/v1/incidents?include=account,incident_status,incident_severity';
    let requestParams: any = {
      "page[per]": perPage,
      "page[number]": page,
    };
    if (reportParams.length) {
      requestParams['w[report_params]'] = JSON.stringify(reportParams).replace(/\\"/g, '"');
    }
    //reset sort when having like report param operator
    if(sort.order && sort.attribute && !reportParams.some((param: ReportParam) => param.operator[0] == 'like')){
      requestParams['s['+sort.attribute+']'] = sort.order;
    }
    return this.http.get(url, {params: requestParams});
  }

  getIncident(id: number) {
    const url = environment.api_url + 'operator/v1/incidents/' + id + '?include=account,incident_status,incident_severity,incident_component,tickets,creator,requester,resolved_by';

    return this.http.get(url);
  }

  getIncidentUpdates(id: number) {
    const url = environment.api_url + 'operator/v1/incident_updates?include=user&q[incident_id_eq]='+id;
    let requestParams: any = {
      "page[per]": 20,
      "page[number]": 1,
      "sort": '-created_at'
    };

    return this.http.get(url, { params: requestParams });
  }
}
