import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BehaviorSubject, map, of } from "rxjs";
import Service from "../utilities/models/service/service";
import ServiceMetric from "../utilities/models/service/serviceMetric";
import { DataItem } from "../utilities/models/service/serviceData";
import { RelationshipDataWithRelation } from "../utilities/models/relationshipDataWithRelations";
import { ServiceMetricRelationAttributes, ServiceMetricRelationRelationships } from "../utilities/models/service/serviceMetricRelation";
import InstalledServiceValue from "../utilities/models/service/installedServiceValue";
import InstalledService from "../utilities/models/service/installedService";
import { DatePipe } from "@angular/common";
import { InstalledServiceUpdateDto } from "../utilities/models/dto/installedServiceUpdateDto";
import { RansackParam } from "../utilities/models/parameters/ransackParam/ransackParam";
import { ServiceCreateDto } from "../utilities/models/dto/serviceCreateDto";
import { ServiceUpdateDto } from "../utilities/models/dto/serviceUpdateDto";
import { ServiceMetricUpdateDto } from "../utilities/models/dto/serviceMetricUpdateDto";
import { ServiceMetricCreateDto } from "../utilities/models/dto/serviceMetricCreateDto";
import { InstalledServiceCreateDto } from "../utilities/models/dto/installedServiceCreateDto";
import { InstalledServiceDetailCreateDto } from "../utilities/models/dto/installedServiceDetailCreateDto";
import { InstalledServiceDetailUpdateDto } from "../utilities/models/dto/installedServiceDetailUpdateDto";
import { ChangeRequestCreateDto } from "../utilities/models/dto/changeRequestCreateDto";
import { forkJoin } from "rxjs";
import { ArrayHelper } from "../utilities/helpers/arrayHelpers";
import { ServiceRemoveRequestDto } from "../utilities/models/dto/serviceRemoveRequestDto";
import { ServiceAddRequestDto } from "../utilities/models/dto/serviceAddRequestDto";

@Injectable({ providedIn: "root" })
export class ServicesService {
  staticServiceKeys: string[] = ['digcore-users','ticketing-assets','digcore-datto-license'];
  staticServiceIds: number[] = [];
  services$ = new BehaviorSubject<Service[]>([]);
  serviceMetrics$ = new BehaviorSubject<ServiceMetric[]>([]);
  additionalService$ = new BehaviorSubject<Service | undefined>(undefined);

  constructor(private http: HttpClient) {

  }

  getManagedUsersService(){
    return of(this.services$.getValue()?.find(service => service.attributes.system_key == 'digcore-users'));
  }

  getManagedEndpointsService(){
    return of(this.services$.getValue()?.find(service => service.attributes.system_key == 'ticketing-assets'));
  }

  getSaasProtectionService(){
    return of(this.services$.getValue()?.find(service => service.attributes.system_key == 'digcore-datto-license'));
  }

  setServices(services: Service[]) {
    this.services$.next(services);
  }

  setServiceMetrics(serviceMetrics: ServiceMetric[]) {
    this.serviceMetrics$.next(serviceMetrics);
  }

  setAdditionalService(service: Service | undefined) {
    this.additionalService$.next(service);
  }

  getServices(pageSize: number = 20, pageIndex: number = 1, params?: RansackParam[]){
    let requestUrl = environment.api_url + 'operator/v1/services?include=service_metrics,uploads';

    let requestParams: any = {
      "page[per]": pageSize,
      "page[number]": pageIndex,
      "sort": "title"
    };
    if(params){
      params.forEach((param: RansackParam) => {
        requestParams[`q[${param.key}_${param.operator}]`] = param.value;
      })
    }

    //if(this.services$.getValue()?.length > 0) return this.services$;
    return this.http.get(requestUrl, { params: requestParams });
      //.pipe(
      //  map((response:any) => response.data.map((bsData: any) => new Service(bsData, response.included)))
      //);
  }

  getInstalledServices(accountId?: number) {
    // add service metric id to request
    const requestUrl = environment.api_url + 'operator/v1/installed_services?include=service,account';
    let requestParams: { [key: string]: string | number | number[] } = {};
    if(accountId) requestParams['q[account_id_eq]'] = accountId;

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAccountInstalledServiceCountValues(installedServiceIds: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details?include=service_metric,installed_service';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[installed_service_id_in][]": installedServiceIds,
      "page[per]": 10000,
      "q[service_metric_metric_type_eq]": "count"
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAdditionalInstalledServiceDetails(installedServiceId: number, serviceMetricIds: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details?include=service_metric,installed_service';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[installed_service_id_eq]": installedServiceId,
      "q[service_metric_id_in][]": serviceMetricIds,
      "page[per]": 10000,
      "page[number]": 1
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getInstalledServiceDetails(installedServiceId: number, serviceMetricId: number) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details?include=service_metric,installed_service';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[installed_service_id_eq]": installedServiceId,
      "q[service_metric_id_eq]": serviceMetricId,
      "page[per]": 10000,
      "page[number]": 1
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getDeletedInstalledServiceDetails(installedServiceId: number, serviceMetricId: number) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details?include=service_metric,installed_service';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[installed_service_id_eq]": installedServiceId,
      "q[service_metric_id_eq]": serviceMetricId,
      "q[deleted_at_null]": 0,
      "q[reviewed_eq]": 'false',
      "page[per]": 10000,
      "page[number]": 1
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getInstalledServiceDetailsIncludeDeleted(installedServiceId: number, serviceMetricId: number) {
    return forkJoin([
        this.getInstalledServiceDetails(installedServiceId, serviceMetricId),
        this.getDeletedInstalledServiceDetails(installedServiceId, serviceMetricId),
      ])
      .pipe(
        map(([response1, response2]: any) => {
          return {
            data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id']),
            included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
            meta: {
              total_count: response1.meta.total_count + response2.meta.total_count,
              total_pages: 1,
              current_page: 1,
            }
          }
        })
      )
  }

  getAllInstalledServices() {
    // add service metric id to request
    const requestUrl = environment.api_url + 'operator/v1/installed_services?include=service,account';
    let requestParams: { [key: string]: string | number | number[] } = {
      //"q[account_id_eq]": accountId
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getCountMetrics(serviceId: number | undefined) {
    let metrics: ServiceMetric[] = [];
    this.services$.getValue()
      .filter(service => service.id == serviceId)
      .map(service => {
        const m: ServiceMetric[] = service.relationships?.service_metrics?.filter((service_metric: RelationshipDataWithRelation<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>) => service_metric.attributes.metric_type == 'detail')?.map(item => new ServiceMetric(item, [])) ?? [];
        metrics = metrics.concat(m);
      })

    return metrics;
  }

  getDetailsMetrics(serviceId: number | undefined) {
    let metrics: ServiceMetric[] = [];
    this.services$.getValue().filter(service => service.id == serviceId).map((service: Service) => {
      metrics = metrics.concat(service.relationships?.service_metrics?.filter((service_metric: RelationshipDataWithRelation<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>) => service_metric.attributes.metric_type == 'detail')?.map(item => new ServiceMetric(item, [])) || [])
    })

    if(metrics.length > 1 && metrics.some(f => f.attributes.title.includes('Office 365'))){
      const usersMetric = metrics.find(f => f.attributes.title.includes('User'));
      const licencesMetric = metrics.find(f => !f.attributes.title.includes('User'));
      if(usersMetric && licencesMetric) {
        metrics = [usersMetric, licencesMetric];
      }
    }

    return metrics;
  }

  getFirstDetailsMetrics(serviceId: number | undefined, installedService: InstalledService | undefined) {
    let metrics: ServiceMetric[] = [];
    this.services$.getValue().filter(service => service.id == serviceId).map(service => {
      metrics = metrics.concat(service.relationships?.service_metrics?.filter((service_metric: RelationshipDataWithRelation<ServiceMetricRelationAttributes, ServiceMetricRelationRelationships>) => service_metric.attributes.metric_type == 'detail')?.map(item => new ServiceMetric(item, [])) || [])
    })

    return metrics.length ? metrics[0].id : 0;
  }

  getCountMetricValue(installedService: InstalledService, serviceMetric: ServiceMetric, installedServiceValues: InstalledServiceValue[]) {
    const installedServiceValue = installedServiceValues?.find( installedServiceValue => {
      return installedServiceValue.relationships?.service_metric?.id == serviceMetric.id && installedServiceValue.relationships?.installed_service?.id == installedService.id;
    });

    return installedServiceValue?.attributes.value.value || 0;
  }

  displayValue(row: DataItem, column: string, purpose: string, serviceMetric: ServiceMetric, service?: Service, openDetailServiceId?: number): string | null {
    const attribute = serviceMetric.getAttributeByCol(column);
    //console.log(row, column, attribute, serviceMetric.attributes.details_explanation)
    if(!attribute) return '';

    if(['joined_at','last_sign_in_at','created_at','last_login_at','registered_at','last_full_disk_scan_at','last_seen_date','date_added','last_login','last_sync_at','last_updated_at','first_check_in','last_check_in_time'].includes(attribute) || attribute.type == 'date') {
      return this.handleDateValue(row, column);
    }
    if(attribute.key == 'memory_size_mib' && row['attributes'][column] > 0){
      if(row['attributes'][column]/1024 > 1) {
        return (row['attributes'][column] / 1024).toFixed(2).toString() + ' GB';
      }else{
        return row['attributes'][column].toString() + ' MB';
      }
    }
    if(attribute.key == 'capacity' || attribute.key == 'free_space' || attribute.key == 'used_space' || attribute.type == 'size_in_bytes'){
      return (row['attributes'][column] / (1024 * 1024 * 1024)).toFixed(2).toString() + ' GB';
    }

    let regExp= /\|/g;
    let val = row['attributes'][column];

    if(val == null) { return ''; }

    if(val.toString().includes('|') && purpose != 'export') {
      val = val.replace(regExp, "<br>");
    }

    if(val.toString() === 'true' || val.toString() === 't'){
      val = 'Yes';
    }
    if(val.toString() === 'false' || val.toString() === 'f'){
      val = 'No';
    }

    /* EMP360-302 — now that we have User Management in Empist 360, we do not want to go to Deskware
    if(service?.attributes.system_key == 'digcore-users' && purpose != 'export'){
      return `<a class="deskware-link" href="https://empist.deskware.com/people/${row['id']}/contacts" target="_blank">${val}</a>`;
    }
    */

    return val;
  }

  handleDateValue(row: DataItem, attr: string): string | null {
    const pipe = new DatePipe('en-US');

    return row['attributes'][attr] ? pipe.transform(row['attributes'][attr], 'MMM d YYYY, hh:mm') : '-';
  }

  updateInstalledService(payload: InstalledServiceUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/installed_services/' + payload.data.id + '/review';

    return this.http.patch(requestUrl, payload);
  }

  getLastSync() {
    let requestUrl = environment.integration_api_url + 'api/synchronizations/latest_by_integration';
    const headers = {
      'x-skip-headers': 'true'
    }
    return this.http.get(requestUrl, { headers: headers});
  }

  getService(id: number) {
    let requestUrl = environment.api_url + 'operator/v1/services/'+id+'?include=service_metrics,uploads';

    return this.http.get(requestUrl);
  }

  createService(payload: ServiceCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/services';

    return this.http.post(requestUrl, payload);
  }

  updateService(payload: ServiceUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/services/' + payload.data.id;

    return this.http.patch(requestUrl, payload);
  }

  createServiceMetric(payload: ServiceMetricCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/service_metrics';

    return this.http.post(requestUrl, payload);
  }

  updateServiceMetric(payload: ServiceMetricUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/service_metrics/' + payload.data.id;

    return this.http.patch(requestUrl, payload);
  }

  getInstalledServiceAccounts(serviceId: number) {
    // add service metric id to request
    const requestUrl = environment.api_url + 'operator/v1/installed_services?include=service,account';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[service_id_eq]": serviceId,
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  addInstalledServiceToAccount(payload: InstalledServiceCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/installed_services';

    return this.http.post(requestUrl, payload);
  }

  removeInstalledService(installedServiceId: number) {
    const requestUrl = environment.api_url + 'operator/v1/installed_services/' + installedServiceId;

    return this.http.delete(requestUrl);
  }

  removeService(serviceId: number) {
    const requestUrl = environment.api_url + 'operator/v1/services/' + serviceId;

    return this.http.delete(requestUrl);
  }

  removeServiceMetric(serviceMetricId: number) {
    const requestUrl = environment.api_url + 'operator/v1/service_metrics/' + serviceMetricId;

    return this.http.delete(requestUrl);
  }

  createInstalledServiceDetail(payload: InstalledServiceDetailCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details';

    return this.http.post(requestUrl, payload);
  }

  updateInstalledServiceDetail(payload: InstalledServiceDetailUpdateDto) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details/' + payload.data.id;

    return this.http.patch(requestUrl, payload);
  }

  deleteService(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/services/' + id;

    return this.http.delete(requestUrl);
  }

  deleteServiceMetric(id: number) {
    const requestUrl = environment.api_url + 'operator/v1/service_metrics/' + id;

    return this.http.delete(requestUrl);
  }

  getInstalledServiceDetailsCount(installedServiceIds: number[], serviceMetricIds: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/installed_services/details_count';
    let requestParams: { [key: string]: string | number | number[] } = {
      "installed_service_ids": installedServiceIds.join(','),
      "service_metric_ids": serviceMetricIds.join(',')
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getAllInstalledServiceDetails(installedServiceIds: number[], serviceMetricIds: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details?include=installed_service,service_metric';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[installed_service_id_in][]": installedServiceIds,
      "q[service_metric_id_in][]": serviceMetricIds,
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  changeRequest(payload: ChangeRequestCreateDto) {
    const requestUrl = environment.api_url + 'operator/v1/installed_services/change_request';

    return this.http.post<any>(requestUrl, payload);
  }

  serviceAddRequest(payload: ServiceAddRequestDto) {
    const requestUrl = environment.api_url + 'operator/v1/contact_requests';

    return this.http.post<any>(requestUrl, payload);

  }

  serviceRemoveRequest(payload: ServiceRemoveRequestDto) {
    const requestUrl = environment.api_url + 'operator/v1/contact_requests';

    return this.http.post<any>(requestUrl, payload);
  }

  getInstalledServiceDetailsByIds(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[id_in]": ids.join(','),
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getDeletedInstalledServiceDetailsByIds(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/installed_service_details';
    let requestParams: { [key: string]: string | number | number[] } = {
      "q[id_in]": ids.join(','),
      "page[per]": 10000,
      "page[number]": 1,
      "q[deleted_at_null]": 0,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getServiceSubscriptions(userId: number) {
    const requestUrl = `${environment.api_url}operator/v1/service_subscriptions?user_id=${userId}`;
    let requestParams: { [key: string]: string | number | number[] } = {
      "page[per]": 10000,
      "page[number]": 1,
    };

    return this.http.get(requestUrl, { params: requestParams });
  }
}
