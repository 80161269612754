import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class ConfigurationsService {
  constructor(private http: HttpClient) {}

  getTimezones() {
    const requestUrl = environment.api_url + 'operator/v1/global_configurations/time_zones';
    return this.http.get(requestUrl);
  }
}