import {AccountRelationAttribute} from "./accountRelationAttribute";
import {RelationshipData} from "../relationshipData";
import {TicketRelationAttributes} from "./ticketRelation";
import {UserRelationAttributes} from "../user/userRelation";
import {ChangeStatusRelationAttributes} from "./changeStatus";
import {ChangeRiskLevelRelationAttributes} from "./changeRiskLevel";
import {ChangeTypeRelationAttributes} from "./changeType";
import {ChangelogApprovalBoardRelationAttributes} from "./changelogApprovalBoardRelation";

export class ChangelogRelationships {
  account?: RelationshipData<AccountRelationAttribute> | undefined;
  requester?: RelationshipData<UserRelationAttributes> | undefined;
  creator?: RelationshipData<UserRelationAttributes> | undefined;
  tickets?: RelationshipData<TicketRelationAttributes>[];
  resolved_by?: RelationshipData<UserRelationAttributes> | undefined;
  change_status?:  RelationshipData<ChangeStatusRelationAttributes> | undefined;
  change_risk_level?:  RelationshipData<ChangeRiskLevelRelationAttributes> | undefined;
  change_type?:  RelationshipData<ChangeTypeRelationAttributes> | undefined;
  assignees?:  RelationshipData<UserRelationAttributes>[];
  agent_approvers?: RelationshipData<UserRelationAttributes>[];
  board_approvers?: RelationshipData<ChangelogApprovalBoardRelationAttributes>[];
}
