import { RelationshipDataWithRelation } from "./relationshipDataWithRelations";
import { IncludedDataWithRelation } from "./includedDataWithRelation";
import {RelationshipData} from "./relationshipData";
import {AccountRelationAttribute} from "./account/accountRelationAttribute";
import {IncludedData} from "./includedData";
import {UserRelationAttributes} from "./user/userRelation";

export class ObjectData<Tattr, Trel> {
  id: number;
  type: string;
  attributes: Tattr;
  relationships?: Trel;

  constructor(data: ObjectData<Tattr, Trel>, included?: Object[]) {
    this.id = +data.id;
    this.type = data.type;
    this.attributes = data.attributes;
  }

  protected static normalizeSingleRelation<Tattr, Trel>(relationData: any, included: Object[], key: string, callback?: Function): RelationshipDataWithRelation<Tattr, Trel> | undefined {
    if (!relationData[key]?.data?.id) return undefined;

    return ObjectData.normalizeRelatedObject<Tattr, Trel>(relationData[key].data, included, callback);
  }

  public static normalizeMultipleRelation<Tattr, Trel>(relationData: any, included: Object[], key: string, callback?: Function): RelationshipDataWithRelation<Tattr, Trel>[] | undefined {
    if (!(relationData[key]?.data?.length > 0)) return undefined;

    return relationData[key].data.map((data: any) => ObjectData.normalizeRelatedObject<Tattr, Trel>(data, included, callback))
  }

  static normalizeRelatedObject<Tattr, Trel>(data: any, included: Object[], callback?: Function) {
    let relatedObject = new RelationshipDataWithRelation<Tattr, Trel>();

    relatedObject.id = +data.id;
    relatedObject.type = data.type;
    const includedDataObject = included.find((includedObject: any) => {
      return includedObject.type == relatedObject.type && includedObject.id == relatedObject.id
    }) as IncludedDataWithRelation<Tattr, Trel>;
    relatedObject.attributes = includedDataObject.attributes;

    if(callback) {
      relatedObject.relationships = callback(includedDataObject.relationships, included);
    }

    return relatedObject;
  }

  static normalizeAccountRelation(relationObject: any, included: Object[]): RelationshipData<AccountRelationAttribute> | undefined {
    let accountRelation = new RelationshipData<AccountRelationAttribute>();
    if (!relationObject?.account?.data?.id) return undefined;

    accountRelation.id = +relationObject.account.data.id;
    accountRelation.type = relationObject.account.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == accountRelation.type &&
        includedObject.id == accountRelation.id
    });
    accountRelation.attributes = (tmp as IncludedData<AccountRelationAttribute>).attributes

    return accountRelation;
  }

  static normalizeUserRelation(relationObject: any, included: Object[], key: string): RelationshipData<UserRelationAttributes> | undefined {
    let userRelation = new RelationshipData<UserRelationAttributes>();
    if (!relationObject[key]?.data?.id) return undefined;

    userRelation.id = +relationObject[key].data.id;
    userRelation.type = relationObject[key].data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == userRelation.type &&
        includedObject.id == userRelation.id
    });
    userRelation.attributes = (tmp as IncludedData<UserRelationAttributes>).attributes

    return userRelation;
  }
}
