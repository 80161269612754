<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'services']">Services</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="service">{{ service.attributes.title }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','services']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-row *ngIf="service && service.id > 0">
      <div nz-col nzSpan="24" class="service-top">
        <div class="service-logo">
          <img [src]="getLogoByService(service)" [alt]="service.getTitle()"/>
        </div>
        <div class="service-info" *ngIf="service && serviceMetric && (service.attributes.external_identifier || staticServiceIds.includes(service.id))">
          <div class="service-title">{{ service.getTitle() }} <span>{{ serviceMetric.getTitle() }} ({{ installedServiceDetails.length }})</span></div>
          <div class="service-text">
            This is the list of {{ serviceMetric.getTitle().toLowerCase() }} billed through the <b>EMPIST 360</b> platform specifically for {{ service.getTitle() }}.
          </div>
        </div>
        <div class="service-info" *ngIf="service && !service.attributes.external_identifier && !staticServiceIds.includes(service.id)">
          <div class="service-title">{{ service.getTitle() }}</div>
          <div class="service-text" *ngIf="installedServiceDetails.length > 0">
            <div *ngFor="let installedServiceDetail of installedServiceDetails">
              {{ installedServiceDetail.attributes.col1 }} {{ getServiceMetricTitleById(installedServiceDetail?.relationships?.service_metric?.id)?.toLowerCase() }}
            </div>
          </div>
        </div>
        <div class="request-change-container">
          <ng-container *ngIf="service && (service.attributes.external_identifier || staticServiceIds.includes(service.id)) && serviceMetric">
            <a [routerLink]="['/app', 'services', 'request-change']" class="request-support">
              Request Support <span nz-icon nzType="question-circle" [nzTheme]="'outline'"></span>
            </a>
            <button nz-button
                    nzShape="round"
                    [nzType]="(setOfCheckedId.size > 0) ? 'primary' : 'default'"
                    [disabled]="setOfCheckedId.size === 0"
                    [nzLoading]="loading"
                    (click)="removeItems()">
              Remove
            </button>
            <button *ngIf="serviceMetricHasAdd"
                    nz-button
                    class="btn-black"
                    nzShape="round"
                    (click)="showAddServiceModal()">
              Add
            </button>
          </ng-container>
          <ng-container *ngIf="service && !(service.attributes.external_identifier || staticServiceIds.includes(service.id))">
            <button nz-button
                    class="btn-black"
                    nzShape="round"
                    [routerLink]="['/app', 'services', service.id, 'request-details']">
              Request Details
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <div nz-row *ngIf="service && !service.attributes.external_identifier && !staticServiceIds.includes(service.id)" class="additional-service-info-text">
      <div nz-col nzSpan="24">
        As of today, you are currently subscribed to {{ service.getTitle() }}. If you would like additional information about this service, please click the request details button to submit a request.
      </div>
    </div>
    <div nz-row *ngIf="serviceDetailsMetrics.length > 1 && service">
      <div nz-col nzSpan="24" class="service-metric-tabs">
        <a *ngFor="let metric of serviceDetailsMetrics"
           [routerLink]="['/app','services', service.id, installedServiceId, 'details', metric.id]"
           class="metric"
           routerLinkActive="active-metric"
           [ngClass]="{'active-metric': serviceMetrics.length == 1}">
          {{ metric.getTitle() }}
        </a>
      </div>
    </div>
    <div nz-row *ngIf="loading" nzJustify="center">
      <div nz-col nzSpan="24" class="data-loading">
        <nz-spin nzSimple></nz-spin>
      </div>
    </div>
    <div nz-row *ngIf="service && (service.attributes.external_identifier || staticServiceIds.includes(service.id))">
      <div nz-col nzSpan="24">
        <div *ngIf="service && serviceMetric" class="installed-service-details" [ngClass]="{ 'hasSelections': setOfCheckedId.size > 0 }">
          <div class="installed-service-details-table" *ngIf="installedServiceDetails.length && !loading">
            <div class="export-row">
              <!-- statis filters begin -->
              <div *ngIf="!loading && staticFilterOptions.length" class="filter-items">
                <div *ngFor="let item of staticFilterOptions"
                     class="filter-item"
                     [ngClass]="{'active': selectedFilterOption.label == item.label}"
                     (click)="applyStaticFilter(item)">
                  {{ item.label }} ({{ item.count }})
                </div>
              </div>
              <!-- statis filters end -->
              <button nz-button nzShape="round" nzType="primary" (click)="exportToExcel()">
                <span nz-icon nzType="file-excel" [nzTheme]="'outline'"></span>
                Export
              </button>
            </div>
            <nz-table #installedServiceValuesTableData
                      [nzData]="installedServiceFilteredDetails"
                      [nzPageSize]="20"
                      nzHideOnSinglePage
                      nzShowSizeChanger
                      [nzPageSizeOptions]="[20,50,100]"
                      [nzTotal]="installedServiceDetails.length"
                      (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
              <thead>
                <tr>
                  <th *ngIf="serviceMetricHasRemove"
                      [nzChecked]="checked"
                      [nzIndeterminate]="indeterminate"
                      (nzCheckedChange)="onAllChecked($event)"></th>
                  <th *ngFor="let column of columns"
                      [nzSortFn]="column.sortFn"
                      [(nzSortOrder)]="column.sortOrder">{{ column.title }}</th>
                  <th *ngIf="service?.attributes?.system_key == 'digcore-users'">Services</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of installedServiceValuesTableData.data; let i = index"
                    [ngClass]="row.attributes.mark_for_deletion == true ? 'marked-for-delete' : ''">
                  <td *ngIf="serviceMetricHasRemove"
                      [nzChecked]="setOfCheckedId.has(row['id'])"
                      (nzCheckedChange)="onItemChecked(row['id'], $event)"></td>
                  <td *ngFor="let attr of keys">
                    <!--
                    EMP360-302 — now that we have User Management in Empist 360, we should link there
                    BUT, non-agent users cannot view Agents, so we should not link!
                  -->
                    @if (['account_agents', 'contacts'].includes(row.type) && ['col1', 'col2', 'col3'].includes(attr)) {
                      <a [routerLink]="['/app', 'users', row['id'], row['type']]">{{ displayValue(row, attr) }}</a>
                    } @else {
                      <span [innerHTML]="displayValue(row, attr)"></span>
                    }
                  </td>
                  <td *ngIf="service?.attributes?.system_key == 'digcore-users'" class="cell-services" (click)="getUserServices(row['id'])">
                    <nz-collapse [nzBordered]="false" [nzGhost]="false">
                      <nz-collapse-panel [nzHeader]="extraTpl" [nzShowArrow]="false">
                        <div *ngFor="let serviceTitle of userServices[row['id']]">
                          {{ serviceTitle }}
                        </div>
                      </nz-collapse-panel>
                    </nz-collapse>
                    <ng-template #extraTpl>
                      <div *ngIf="userServices[row['id']]">
                      </div>
                      <div *ngIf="!userServices[row['id']]">
                        <nz-spin nzSimple *ngIf="userServiceLoading == row['id']"></nz-spin>
                        <span *ngIf="userServiceLoading != row['id']">
                          Show Services <span nz-icon nzType="caret-down" nzTheme="outline"></span>
                        </span>
                      </div>
                    </ng-template>
                  </td>
                </tr>
                <tr *ngIf="installedServiceFilteredDetails.length && serviceMetric.attributes.title == 'VMware vCenter: Virtual Machines'" class="summary-row">
                  <td></td>
                  <td><strong>Total vCPUs and vRAM</strong></td>
                  <td><strong>{{ totalCpu }}</strong></td>
                  <td><strong>{{ totalRamInMB }} MB</strong></td>
                </tr>
              </tbody>
            </nz-table>
            <div class="details-info" *ngIf="hasPendingRemovals">
              <div class="details">
                <div class="marked-for-delete-items">Pending Removal</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
<custom-loader *ngIf="loaderVisible"></custom-loader>
<div class="notification-banner" *ngIf="false">
  Please choose one or multiple items to remove
</div>
