import { ObjectData } from "../objectData";
import { InvoiceAttributes } from "./invoiceAttributes";
import { InvoiceRelationships } from "./invoiceRelationships";
import { RelationshipData } from "../relationshipData";
import { LineItemRelationAttribute } from "./lineItemRelationAttribute";
import { IncludedData } from "../includedData";
import { InvoiceStatusRelationAttribute } from "./InvoiceStatusRelationAttribute";

export class Invoice extends ObjectData<InvoiceAttributes, InvoiceRelationships> {

  constructor(data: ObjectData<InvoiceAttributes, InvoiceRelationships>, included: Object[]) {
    super(data, included);

    if (data.relationships && included) {
      this.relationships = Invoice.normalizeRelation(data.relationships, included);
    }
  }


  private static normalizeRelation(relationObject: any, included: Object[]): InvoiceRelationships {
    let invoiceRelationships = new InvoiceRelationships();

    invoiceRelationships.account = Invoice.normalizeAccountRelation(relationObject, included);
    invoiceRelationships.line_items = Invoice.normalizeLineItemsRelation(relationObject, included);
    invoiceRelationships.invoice_status = Invoice.normalizeInvoiceStatusRelation(relationObject, included);


    return invoiceRelationships;
  }

  private static normalizeLineItemsRelation(relationObject: any, included: Object[]): RelationshipData<LineItemRelationAttribute>[] | undefined  {
    if (!(relationObject?.line_items?.data?.length > 0)) return undefined;

    return relationObject.line_items.data.map((item: any) => {
      let lineItemRelation = new RelationshipData<LineItemRelationAttribute>();

      lineItemRelation.id = +item.id;
      lineItemRelation.type = item.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == lineItemRelation.type &&
          includedObject.id == lineItemRelation.id
      });
      lineItemRelation.attributes = (tmp as IncludedData<LineItemRelationAttribute>).attributes

      return lineItemRelation;
    })
  }

  private static normalizeInvoiceStatusRelation(relationObject: any, included: Object[]): RelationshipData<InvoiceStatusRelationAttribute> | undefined  {
    let relation = new RelationshipData<InvoiceStatusRelationAttribute>();
    if (!relationObject?.invoice_status?.data?.id) return undefined;

    relation.id = +relationObject.invoice_status.data.id;
    relation.type = relationObject.invoice_status.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == relation.type &&
        includedObject.id == relation.id
    });
    relation.attributes = (tmp as IncludedData<InvoiceStatusRelationAttribute>).attributes

    return relation;
  }
}
