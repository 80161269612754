import {AccountRelationAttribute} from "./accountRelationAttribute";
import {RelationshipData} from "../relationshipData";
import {IncidentStatusRelationAttributes} from "./incidentStatusRelation";
import {IncidentSeverityRelationAttributes} from "./incidentSeverityRelation";
import {IncidentComponentRelationAttributes} from "./incidentComponentRelation";
import {TicketRelationAttributes} from "./ticketRelation";
import {UserRelationAttributes} from "../user/userRelation";

export class IncidentRelationships {
  account?: RelationshipData<AccountRelationAttribute> | undefined;
  incident_status?: RelationshipData<IncidentStatusRelationAttributes> | undefined;
  incident_severity?: RelationshipData<IncidentSeverityRelationAttributes> | undefined;
  incident_component?: RelationshipData<IncidentComponentRelationAttributes> | undefined;
  requester?: RelationshipData<UserRelationAttributes> | undefined;
  creator?: RelationshipData<UserRelationAttributes> | undefined;
  tickets?: RelationshipData<TicketRelationAttributes>[];
  resolved_by?: RelationshipData<UserRelationAttributes> | undefined;
}
