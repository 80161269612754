import {ObjectData} from "../objectData";
import {ChangelogApprovalDecisionAttributes} from "./changelogApprovalDecisionAttributes";
import {ChangelogApprovalDecisionRelationships} from "./changelogApprovalDecisionRelationships";

export class ChangelogApprovalDecision extends ObjectData<ChangelogApprovalDecisionAttributes, ChangelogApprovalDecisionRelationships>{
  constructor(data: ObjectData<ChangelogApprovalDecisionAttributes, ChangelogApprovalDecisionRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = ChangelogApprovalDecision.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ChangelogApprovalDecisionRelationships {
    let relationships = new ChangelogApprovalDecisionRelationships();

    relationships.agent = ChangelogApprovalDecision.normalizeUserRelation(relationObject, included, 'agent');

    return relationships;
  }
}
