import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ExtraEmailCreateDto } from '../utilities/models/dto/extraEmailCreateDto';
import { ExtraEmailUpdateDto } from '../utilities/models/dto/extraEmailUpdateDto';

@Injectable({ providedIn: 'root' })
export class ExtraEmailsService {
  constructor(private http: HttpClient) {}

  create(payload: ExtraEmailCreateDto) {
    const requestURL = `${environment.api_url}operator/v1/extra_emails`;

    return this.http.post(requestURL, payload);
  }

  delete(extraEmailId: number | string) {
    const requestURL = `${environment.api_url}operator/v1/extra_emails/${extraEmailId}`;

    return this.http.delete(requestURL);
  }

  update(extraEmailId: number | string, payload: ExtraEmailUpdateDto) {
    const requestURL = `${environment.api_url}operator/v1/extra_emails/${extraEmailId}`;

    return this.http.patch(requestURL, payload);
  }
}
