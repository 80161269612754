import {IncidentUpdateAttributes} from "./incidentUpdateAttributes";
import {IncidentUpdateRelationships} from "./incidentUpdateRelationships";
import {ObjectData} from "../objectData";

export class IncidentUpdate extends ObjectData<IncidentUpdateAttributes, IncidentUpdateRelationships>{
  constructor(data: ObjectData<IncidentUpdateAttributes, IncidentUpdateRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = IncidentUpdate.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): IncidentUpdateRelationships {
    let relationships = new IncidentUpdateRelationships();

    relationships.user = IncidentUpdate.normalizeUserRelation(relationObject, included, 'user');

    return relationships;
  }
}
