export interface UserCreateAttributesDto {
  active: boolean;
  email: string;
  firstname: string;
  lastname: string;
  mobile_phone?: string;
  office_phone?: string;
  job_title?: string;

  account?: number | string;
  price_tier?: number | string;
}

export interface UserCreateRelationshipsDto {
  account: {
    data: {
      id: number;
      type: string;
    }
  }
  price_tier: {
    data: {
      id: number;
      type: string;
    }
  }
}

export interface UserCreateUserPanelDto {
  data: {
    type: string;
    attributes: {
      bypass_ip_restriction: boolean;
    };
  }
}

export class UserCreateDto {
  data: {
    attributes: UserCreateAttributesDto;
    relationships: UserCreateRelationshipsDto;
    nested_attributes: UserCreateUserPanelDto;
  }
  type: string;

  constructor(payload: any) {
    this.type = 'contacts',
    this.data = {
      attributes: {
        ...payload,
        active: true,
      },
      relationships: {
        account: {
          data: {
            id: +payload.account,
            type: 'accounts'
          }
        },
        price_tier: {
          data: {
            id: +payload.price_tier,
            type: 'price_tiers'
          }
        }
      },
      nested_attributes: {
        data: {
          attributes: {
            bypass_ip_restriction: false,
          },
          type: 'user_panels'
        }
      }
    }

    delete(this.data.attributes.account);
    delete(this.data.attributes.price_tier);
  }
}
