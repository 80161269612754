export interface ExtraEmailUpdateAttributesDto {
  email: string;
}

export class ExtraEmailUpdateDto {
  data: {
    attributes: ExtraEmailUpdateAttributesDto;
  }

  constructor(email: string) {
    this.data = {
      attributes: { email },
    }
  }
}
