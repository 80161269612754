<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Invoices</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span
            class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div class="list-container">
          <div nz-row>
            <div nz-col nzSpan="24" class="invoices-top">
              <div>
                <h1>Invoices <span *ngIf="!loading">- {{ activeInvoiceView.title }} ({{ total }})</span></h1>
                <div>
                  Manage all invoices for your organization effortlessly with EMPIST 360.<br/>Reach out to our helpful billing support team for any assistance you need.<br/>
                </div>
              </div>
              <div class="info-boxes">
                <div *ngFor="let invoiceView of invoiceViews"
                     [ngClass]="invoiceView.title.toLowerCase()"
                     class="info-box"
                     (click)="applyInvoiceView(invoiceView)">
                  <div class="info-box-title"><span>{{invoiceView.title}}</span></div>
                  <div class="info-box-content">
                    <span *ngIf="!invoiceView.loading; else loadingCnt">{{ invoiceView.totalCount }}</span>
                    <ng-template #loadingCnt>
                      <nz-spin nzSimple></nz-spin>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div nz-row class="filters-container">
            <div nz-col nzSpan="20">
              <!-- filters begin -->
              <form nz-form
                    nzLayout="vertical"
                    [formGroup]="filtersFormGroup">
                <div nz-row>
                  <div nz-col nzSpan="24" nzOffset="0" class="filters">
                    <span class="no-filters-start-phrase" *ngIf="filtersCountFormGroup == 0">All invoices</span>
                    <span class="clear-all-filters" *ngIf="filtersCountFormGroup > 0" (click)="clearAllFilters()">Clear all</span>
                    <span class="filters-start-phrase" *ngIf="filtersCountFormGroup > 0">Show me invoices that</span>
                    <div *ngFor="let filter of getFiltersByFormGroupControls(); let i = index" class="asset-resource-filter">
                      <span class="remove-filter" (click)="removeFilterControl(filter)">
                        <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
                      </span>
                      <span class="filter-prefix">
                        {{ i > 0 ? ' and' : ''}} {{getFilterLabel(filter.computed_key, filter.label) }}
                      </span>
                      <div class="filter-control">
                        <div class="filter-control-container">
                          <div *ngIf="filter.type == 'select'">
                            <nz-select [formControlName]="filter.computed_key"
                                       [nzPlaceHolder]="'Select'"
                                       nzShowSearch
                                       [nzTokenSeparators]="[',']"
                                       nzBorderless
                                       [nzBackdrop]="false"
                                       [nzShowArrow]="true"
                                       nzServerSearch
                                       (nzOnSearch)="onSearch($event, filter)"
                                       (ngModelChange)="onSelect($event, filter)">
                              <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                         [nzValue]="option.value"
                                         [nzLabel]="option.doc_count ? option.label + '('+option.doc_count+')' : option.label"></nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="['text','string'].includes(filter.type)">
                            <input nz-input
                                   [formControlName]="filter.computed_key"
                                   (ngModelChange)="onSelect($event, filter)"
                                   nzBorderless
                                   placeholder="type to search"/>
                          </div>
                          <div *ngIf="filter.type == 'simplified_date'">
                            <nz-select [nzPlaceHolder]="'Select'"
                                       [formControlName]="filter.computed_key"
                                       nzBorderless
                                       [nzShowArrow]="true"
                                       [nzBackdrop]="false"
                                       (ngModelChange)="onSelect($event, filter)">
                              <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                         [nzValue]="option.value"
                                         [nzLabel]="option.label"></nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="filter.type == 'date'">
                            <nz-range-picker [nzShowTime]="false"
                                             [formControlName]="filter.computed_key"
                                             (ngModelChange)="onSelect($event, filter)"
                                             [nzAllowClear]="false">
                            </nz-range-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="add-filter-container disabled"
                          *ngIf="!addFilterEnabled">
                      <span class="tooltip" nz-tooltip [nzTooltipTitle]="'Please fill in all selected filters to add more'">
                        <span class="add-filter-label">Add filter</span>
                        <a class="add-filter">
                          <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                        </a>
                      </span>
                    </span>
                    <span class="add-filter-container"
                          nz-dropdown nzTrigger="click"
                          [nzDropdownMenu]="filtersAvailable"
                          [nzPlacement]="'bottomLeft'"
                          [nzDisabled]="!addFilterEnabled"
                          *ngIf="addFilterEnabled && availableFilterOptions.length > 0">
                        <span class="add-filter-label" *ngIf="filtersCountFormGroup == 0">Add filter</span>
                        <a class="add-filter">
                          <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                        </a>
                      </span>
                    <nz-dropdown-menu #filtersAvailable="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item *ngFor="let filter of availableFilterOptions" (click)="addFilterControl(filter.key)">
                          <span>{{ filter.label }}</span>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </form>
              <!-- filters end -->
            </div>
            <div nz-col nzSpan="4" class="export">
              <button nz-button nzShape="round" nzType="primary" [nzLoading]="exportLoading" (click)="exportInvoices()">
                <span nz-icon nzType="file-excel" [nzTheme]="'outline'"></span>
                Export
              </button>
            </div>
          </div>
          <div nz-row class="list-description">
            <div nz-col nzSpan="24">
              <nz-table [nzData]="invoices"
                        [nzFrontPagination]="false"
                        [nzLoading]="loading"
                        [nzTotal]="total"
                        [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex"
                        [nzBordered]="false"
                        (nzQueryParams)="onQueryParamsChange($event)">
                <thead>
                <tr>
                  <th nzColumnKey="uniq_number" [nzSortFn]="true">Invoice</th>
                  <th *ngIf="loggedInUser && loggedInUser.attributes.type == 'Ticketing::Agent'" nzColumnKey="account_id" [nzSortFn]="true">Account</th>
                  <th nzColumnKey="total_amount" [nzSortFn]="true">Amount</th>
                  <th nzColumnKey="invoice_balance" [nzSortFn]="true">Balance</th>
                  <th nzColumnKey="status_of_invoice" [nzSortFn]="true">Status</th>
                  <th nzColumnKey="date_created" [nzSortFn]="true">Invoice Date</th>
                  <th nzColumnKey="due_date" [nzSortFn]="true">Due Date</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let invoice of invoices">
                  <td><a [routerLink]="['/app', 'invoices', invoice.id]">{{ invoice.attributes.uniq_number }}</a></td>
                  <td *ngIf="loggedInUser && loggedInUser.attributes.type == 'Ticketing::Agent'">{{ invoice.relationships?.account?.attributes?.title ?? '-' }}</td>
                  <td>{{ invoice.attributes.total_amount | currency }}</td>
                  <td>{{ invoice.attributes.invoice_balance | currency }}</td>
                  <td>{{ invoice.relationships?.invoice_status?.attributes?.title ?? '-' }}</td>
                  <td>{{ invoice.attributes.date_created | date }}</td>
                  <td>{{ invoice.attributes.due_date | date }}</td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
