import { Component, DestroyRef, OnInit } from '@angular/core';
import { AssetResourcesService } from "src/app/services/assetResources.service";
import { AssetResource } from "src/app/utilities/models/assetResource/assetResource";
import { ActivatedRoute, Params } from "@angular/router";
import { UsersService } from "src/app/services/users.service";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { FormControl, FormGroup } from "@angular/forms";
import { User } from "src/app/utilities/models/user/user";
import { UsersListService } from "src/app/services/usersList.service";
import { AssetResourceUpdateDto } from "src/app/utilities/models/dto/assetResourceUpdateDto";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.scss']
})
export class AssetComponent implements OnInit{
  asset: AssetResource;
  permission: Permission | undefined;
  fieldInEditMode: string = '';
  assetResourceForm: FormGroup;
  loading: boolean = false;
  contactsList: User[] = [];
  editMode: boolean = false;

  constructor(private assetResourcesService: AssetResourcesService,
              private userService: UsersService,
              private userListService: UsersListService,
              private activatedRoute: ActivatedRoute,
              private destroyRef: DestroyRef) {
  }


  ngOnInit() {
    this.permission = this.userService.findPermission('Ticketing::AssetResource', 'ticketing/operator/v1/asset_resources', 'update');
    this.activatedRoute.params.subscribe({
        next: (params: Params) => {
          this.showAsset(params['id']);
          this.initForm();
        }
      }
    );
  }

  showAsset(id: number) {
    this.loading = true;
    this.assetResourcesService.getAssetResource(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next:(response: any) => {
          if(response.data) {
            this.asset = new AssetResource(response.data, response.included);
            this.setForm();
            this.loading = false;
          }
        }
      });
  }

  setEditModeForField(fieldName: string) {
    this.fieldInEditMode = fieldName;
    this.setEditMode(true);
  }

  setEditMode(value: boolean) {
    this.editMode = value;
    if(!value) {
      this.fieldInEditMode = '';
    }
  }

  disableByProperty(attributeName: string): boolean {
    switch (this.permission?.associated_attrs[attributeName]) {
      case 'visible':
        return true;
      case 'editable':
        return false;
      default:
        return true;
    }
  }


  private initForm(){
    this.assetResourceForm = new FormGroup({
      'contact': new FormControl<number | undefined>({ value: undefined, disabled: this.disableByProperty('contact_id') }),
    })
  }

  private setForm(){
    if(this.asset?.relationships?.contact?.id) {
      this.contactsList = [ new User(this.asset.relationships.contact, []) ];
      this.assetResourceForm.get('contact')?.setValue(+this.asset.relationships.contact.id);
    }
  }

  searchContacts(searchString: string) {
    this.getContacts(true, searchString);
  }

  getContacts(fieldStatus: boolean, searchString: string = '') {
    if(fieldStatus){
      if(this.userService.loggedInUser.relationships?.account?.id) {
        this.userListService.getAccountRequester(this.userService.loggedInUser.relationships.account.id, searchString)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next:(response: any) => {
              this.contactsList = response.data.map( (user: any) => new User(user, response.included));
            }
          });
      }
    }
  }

  onSubmit() {
    this.loading = true;
    const payload = this.prepareAssetResourceUpdatePayload();
    this.assetResourcesService.updateAssetResource(payload)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
      next: _ => {
        this.loading = false;
        this.showAsset(this.asset.id);
        this.fieldInEditMode = '';
      }
    });
  }

  private prepareAssetResourceUpdatePayload(): AssetResourceUpdateDto{
    const assetResourceUpdateAttributes = {}
    const assetResourceUpdateRelationships = {
      contact: {
        data: {
          id: this.assetResourceForm.get('contact')?.value,
          type: 'users'
        }
      }
    };

    return new AssetResourceUpdateDto(this.asset.id, "deskware_tech_assets", assetResourceUpdateAttributes, assetResourceUpdateRelationships);
  }
}
