<nz-layout class="assets-layout" *ngIf="asset">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'assets']">Assets</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="asset?.attributes?.title">{{ asset.attributes.title }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','assets']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <form class="user-information-form"
          nz-form
          nzLayout="vertical"
          [formGroup]="assetResourceForm">
      <div nz-row *ngIf="asset">
        <div nz-col nzSpan="24">
          <div nz-row>
            <div nz-col nzSpan="24">
              <div class="asset-title">{{ asset.attributes.title }}</div>
              <div class="asset-description">{{ asset.attributes.description }}</div>
            </div>
          </div>
          <div nz-row nzGutter="16">
            <div nz-col nzSpan="8" [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16" [nzXl]="8">
              <div class="asset-head-item"><span>01.</span> Asset Information</div>
              <div class="field-row">
                <label class="field-label">Type</label>
                <div class="field-control">{{ asset.relationships?.asset_resource_type?.attributes?.title || '-' }}</div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Status</label>
                <div class="field-control">{{ asset.relationships?.asset_resource_status?.attributes?.title || '-' }}</div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Manufacturer</label>
                <div class="field-control">{{ asset.relationships?.asset_resource_manufacturer?.attributes?.title || '-' }}</div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Model Number</label>
                <div class="field-control">{{ asset.attributes.model_number || '-' }}</div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Serial Number</label>
                <div class="field-control">{{ asset.attributes.serial_number || '-' }}</div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Location</label>
                <div class="field-control">{{ asset.attributes.location || '-' }}</div>
                <div class="field-actions"></div>
              </div>
            </div>
            <div nz-col nzSpan="8" [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16" [nzXl]="8">
              <div class="asset-head-item"><span>02.</span> Purchase Information</div>
              <div class="field-row">
                <label class="field-label">Purchase Date</label>
                <div class="field-control">
                  <span *ngIf="asset.attributes.purchase_date">{{ asset.attributes.purchase_date | date: 'MMM d YYYY' }}</span>
                  <span *ngIf="!asset.attributes.purchase_date">-</span>
                </div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Warranty Expiration</label>
                <div class="field-control">
                  <span *ngIf="asset.attributes.warranty_expiration_date">{{ asset.attributes.warranty_expiration_date | date: 'MMM d YYYY' }}</span>
                  <span *ngIf="!asset.attributes.warranty_expiration_date">-</span>
                </div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Purchase Amount</label>
                <div class="field-control">
                  <span *ngIf="asset.attributes.purchase_amount">{{ asset.attributes.purchase_amount | currency:'USD' }}</span>
                  <span *ngIf="!asset.attributes.purchase_amount">-</span>
                </div>
                <div class="field-actions"></div>
              </div>
            </div>
            <div nz-col nzSpan="8" [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16" [nzXl]="8">
              <div class="asset-head-item"><span>03.</span> Related Items</div>
              <div class="field-row">
                <label class="field-label">Contact</label>
                <div class="field-control">
                  <span *ngIf="fieldInEditMode != 'contact'; else contactEdit" (click)="setEditModeForField('contact')">
                    {{ asset.relationships?.contact?.attributes?.fullname || '-' }}
                  </span>
                  <ng-template #contactEdit>
                    <nz-select nzShowSearch
                               nzBorderless
                               nzPlaceHolder="Select"
                               formControlName="contact"
                               class="fr-align-left"
                               (nzOnSearch)="searchContacts($event)"
                               (nzOpenChange)="getContacts($event)">
                      <nz-option *ngFor="let contact of contactsList"
                                 [nzLabel]="contact.fullname() + ' ('+contact.attributes.email+')'"
                                 [nzValue]="contact.id">
                      </nz-option>
                    </nz-select>
                  </ng-template>
                </div>
                <div class="field-actions">
                  <div *ngIf="fieldInEditMode != 'contact'">
                    <span nz-icon nzType="edit" [nzTheme]="'outline'" (click)="setEditModeForField('contact')" *ngIf="!disableByProperty('contact_id')"></span>
                    <span nz-icon nzType="lock" [nzTheme]="'outline'" *ngIf="disableByProperty('contact_id')" class="no-edit"></span>
                  </div>
                </div>
              </div>
              <div class="field-row">
                <label class="field-label">Managed By</label>
                <div class="field-control">{{ asset.relationships?.managed_by?.attributes?.fullname || '-' }}</div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Related Assets</label>
                <div class="field-control multiline-content">
                  <div *ngFor="let relatedAsset of asset.relationships?.related_asset_resources">
                    {{ relatedAsset.attributes.title }}
                  </div>
                  <span *ngIf="!asset.relationships?.related_asset_resources?.length">-</span>
                </div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Upload</label>
                <div class="field-control multiline-content">
                  <div *ngFor="let upload of asset.relationships?.uploads">
                    {{ upload.attributes.title }}
                  </div>
                  <span *ngIf="!asset.relationships?.uploads?.length">-</span>
                </div>
                <div class="field-actions"></div>
              </div>
              <div class="field-row">
                <label class="field-label">Tickets</label>
                <div class="field-control multiline-content">
                  <div *ngFor="let ticket of asset.relationships?.tickets">
                    {{ ticket.attributes.code }}: {{ ticket.attributes.subject }}
                  </div>
                  <span *ngIf="!asset.relationships?.tickets?.length">-</span>
                </div>
                <div class="field-actions"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div nz-row *ngIf="editMode" class="asset-form-actions">
        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16" [nzXl]="24">
          <button nz-button nzShape="round" nzType="default" (click)="setEditMode(false)">Cancel</button>
          <button nz-button nzShape="round" nzType="primary" (click)="onSubmit()" [nzLoading]="loading">Save</button>
        </div>
      </div>
    </form>
  </nz-content>
</nz-layout>