import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {ReportParam} from "../utilities/models/parameters/reportParam/reportParam";
import {SortParam} from "../utilities/models/parameters/sortParam/sortParam";

@Injectable({ providedIn: "root" })
export class ChangelogsService {
  constructor(private http: HttpClient) { }

  getChangelogs(perPage: number = 20, page: number = 1, reportParams: ReportParam[], sort: SortParam) {
    const url = environment.api_url + 'operator/v1/change_logs?include=creator,account,assignees,creator,tickets,change_risk_level,change_status,change_status.active,change_type';
    let requestParams: any = {
      "page[per]": perPage,
      "page[number]": page,
    };
    if (reportParams.length) {
      requestParams['w[report_params]'] = JSON.stringify(reportParams).replace(/\\"/g, '"');
    }
    //reset sort when having like report param operator
    if(sort.order && sort.attribute && !reportParams.some((param: ReportParam) => param.operator[0] == 'like')){
      requestParams['s['+sort.attribute+']'] = sort.order;
    }

    return this.http.get(url, {params: requestParams});
  }

  getChangelog(id: number) {
    const url = environment.api_url + 'operator/v1/change_logs/' + id + '?include=account,agent_approvers,asset_resources,assignees,board_approvers.agents,change_risk_level,change_status,change_type,change_updates,creator,tickets';

    return this.http.get(url);
  }

  getChangelogUpdates(id: number) {
    const url = environment.api_url + 'operator/v1/change_updates?include=user&q[incident_id_eq]='+id;
    let requestParams: any = {
      "page[per]": 20,
      "page[number]": 1,
      "sort": '-created_at'
    };

    return this.http.get(url, { params: requestParams });
  }

  getChangelogDecisions(id: number) {
    const url = environment.api_url + 'operator/v1/change_approval_decisions?include=agent&q[change_log_id_eq]='+id;
    let requestParams: any = {
      "page[per]": 50,
      "page[number]": 1,
      "sort": '-created_at'
    };

    return this.http.get(url, { params: requestParams });
  }

  getChangeApprovalBoards() {
    const url = environment.api_url + 'operator/v1/change_approval_boards';
    let requestParams: any = {
      "page[per]": 50,
      "page[number]": 1,
      "sort": 'title'
    };

    return this.http.get(url, { params: requestParams });
  }
}
