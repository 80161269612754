import { ObjectData } from "../objectData";
import { DattoLicenseAttributes } from "./dattoLicenseAttributes";
import { DattoLicenseRelationships } from "./dattoLicenseRelationships";

export class DattoLicense extends ObjectData<DattoLicenseAttributes, DattoLicenseRelationships>{
  constructor(data: ObjectData<DattoLicenseAttributes, DattoLicenseRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = DattoLicense.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): DattoLicenseRelationships {
    let relationships = new DattoLicenseRelationships();

    relationships.account = DattoLicense.normalizeAccountRelation(relationObject, included);

    return relationships;
  }
}
