import { Component, computed, DestroyRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AccountsService } from 'src/app/services/accounts.service';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UsersService } from 'src/app/services/users.service';
import { UserUpdateDto } from 'src/app/utilities/models/dto/userUpdateDto';
import { RelatedAggsAttributes } from 'src/app/utilities/models/user/relatedAggs';
import { User } from 'src/app/utilities/models/user/user';

interface IFormField {
  label: string;
  name: string;
  required: boolean;
  type: 'boolean' | 'number' | 'password' | 'phone' | 'selector' | 'text' | 'wysiwyg';
  hasRequestedOptions?: boolean;
  isClearable?: boolean;
  multiple?: boolean;
  options?: {
    label: string;
    value: number | string;
  }[];
  value?: any;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrl: './user-edit.component.scss'
})
export class UserEditComponent implements OnInit {
  fields: IFormField[] = [
    {
      label: 'Active',
      name: 'active',
      required: false,
      type: 'boolean',
    },
    {
      label: 'Account',
      name: 'account',
      options: [],
      hasRequestedOptions: false,
      required: true,
      type: 'selector',
    },
    {
      label: 'Prefix',
      name: 'title_prefix',
      options: [
        { label: 'Mr.', value: 'mr' },
        { label: 'Mrs.', value: 'mrs' },
        { label: 'Ms.', value: 'ms' },
      ],
      hasRequestedOptions: true,
      isClearable: true,
      required: false,
      type: 'selector',
    },
    {
      label: 'First name',
      name: 'firstname',
      required: true,
      type: 'text',
    },
    {
      label: 'Middle initials',
      name: 'middlename',
      required: false,
      type: 'text',
    },
    {
      label: 'Last name',
      name: 'lastname',
      required: true,
      type: 'text',
    },
    {
      label: 'Role',
      name: 'role',
      options: [],
      hasRequestedOptions: false,
      required: true,
      type: 'selector',
    },
    {
      label: 'Job title',
      name: 'job_title',
      required: false,
      type: 'text',
    },
    {
      label: 'Mobile phone',
      name: 'mobile_phone',
      required: false,
      type: 'phone',
      value: '',
    },
    {
      label: 'Home phone',
      name: 'home_phone',
      required: false,
      type: 'phone',
      value: '',
    },
    {
      label: 'Time Zone',
      name: 'time_zone',
      options: [],
      hasRequestedOptions: false,
      required: true,
      type: 'selector',
    },
    {
      label: 'Office phone',
      name: 'office_phone',
      required: false,
      type: 'phone',
      value: '',
    },
    {
      label: 'Extension',
      name: 'extension',
      required: false,
      type: 'text',
    },
    {
      label: 'Primary Contact',
      name: 'primary',
      required: false,
      type: 'boolean',
    },
    {
      label: 'VIP',
      name: 'vip',
      required: false,
      type: 'boolean',
    },
    {
      label: 'Email',
      name: 'email',
      required: true,
      type: 'text',
    },
    {
      label: 'Password',
      name: 'password',
      required: false,
      type: 'password',
    },
    {
      label: 'Confirm password',
      name: 'confirm_password',
      required: false,
      type: 'password',
    },
    {
      label: 'Type',
      name: 'price_tier',
      options: [],
      hasRequestedOptions: false,
      required: true,
      type: 'selector',
    },
    {
      label: 'Two factor authentication',
      name: 'otp_enabled',
      required: false,
      type: 'boolean',
    },
    {
      label: 'Notes',
      name: 'notes',
      required: false,
      type: 'wysiwyg',
    },
  ];
  formGroup: FormGroup = new FormGroup({});
  isLoading: boolean = false;
  isLoaderVisible: boolean = false;
  isLoadingOptions: string | undefined;
  loggedInUser: User;
  user: User;
  userRelatedAggs = signal<RelatedAggsAttributes | undefined>(undefined);

  constructor(private destroyRef: DestroyRef,
              private activateRoute: ActivatedRoute,
              private message: NzMessageService,
              private accountsService: AccountsService,
              private configurationsService: ConfigurationsService,
              private loaderService: LoaderService,
              private usersService: UsersService) {}

  hasRelatedAggs = computed(() => {
    if (this.userRelatedAggs()) {
      const hasRequesterTickets = (this.userRelatedAggs()?.requester_tickets && Object.keys(this.userRelatedAggs()?.requester_tickets as object).length);
      const hasWatcherTickets = (this.userRelatedAggs()?.watcher_tickets && Object.keys(this.userRelatedAggs()?.watcher_tickets as object).length);

      if (hasRequesterTickets || hasWatcherTickets) return true;
    }

    return false;
  });

  ngOnInit() {
    this.loggedInUser = this.usersService.loggedInUser;
    this.observeLoaderService();
    this.initForm();
    this.activateRoute.params.subscribe({
      next: (params: Params) => {
        this.getUser(params['id'], params['type']);
      }}
    );
  }

  getUser(id: number, type: string) {
    this.isLoading = true;
    this.usersService.getUser(id, type)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.isLoading = false;
          this.user = new User(response.data, response.included);
          this.getRelatedAggs();
          this.setForm();
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        }
      })
  }

  onSelectOpenChange(visibility: boolean, field: string) {
    if (!visibility) return;

    const formField = this.fields.find((f) => f.name == field);

    if (formField?.hasRequestedOptions) return;

    if (field === 'account') {
      this.getAccounts(formField);
    }

    if (field === 'price_tier') {
      this.getPriceTiers(formField);
    }

    if (field === 'role') {
      this.getRoles(formField);
    }

    if (field === 'time_zone') {
      this.getTimezones(formField);
    }
  }

  onSetPhoneNumber(phone: string | null, field: string) {
    this.formGroup.get(field)?.setValue(phone);
  }

  onSubmit() {
    if (!this.formGroup?.valid) return;

    this.loaderService.setProcessing(true);
    this.loaderService.setLoaderVisible(true);
    this.loaderService.setLoadingText('Updating user…');
    this.loaderService.setLoadedText(`${this.user.fullname()} was updated!`);

    const payload: UserUpdateDto = this.prepareUserPayload();
    this.usersService.updateUser(payload, this.user.type)
    .subscribe({
      next: (response: any) => {
        this.formGroup.reset();
        this.loaderService.setProcessing(false);

          setTimeout(() => {
            this.loaderService.setLoaderVisible(false);

            if (response?.data?.id) {
              this.getUser(response.data.id, response.data.type);
            }
          }, 3000);
        },
        error: (error) => {
          this.loaderService.setLoaderVisible(false);
          this.message.error(error?.errors[0]?.detail, { nzDuration: 4000, nzPauseOnHover: false });
        }
      });
  }

  private getRelatedAggs() {
    if (!this.user) return;

    this.usersService.getUserRelatedAggs(this.user.id, this.user.type)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (response?.data?.length) {
            this.userRelatedAggs.set({ ...response?.data?.[0]?.attributes });
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  private getAccounts(field: IFormField | undefined) {
    if (!field) return;

    this.isLoadingOptions = field.name;
    this.accountsService.getAccounts()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.isLoadingOptions = '';
          field.hasRequestedOptions = true;

          if (response?.data?.length) {
            this.setFieldOptionsFromResponseData(field, response);
          }
        },
        error: (error) => {
          this.isLoadingOptions = '';
          console.log(error);
        },
      });
  }

  private getPriceTiers(field: IFormField | undefined) {
    if (!field) return;

    this.isLoadingOptions = field.name;
    this.usersService.getPriceTiers()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.isLoadingOptions = '';
          field.hasRequestedOptions = true;

          if (response?.data?.length) {
            this.setFieldOptionsFromResponseData(field, response);
          }
        },
        error: (error) => {
          this.isLoadingOptions = '';
          console.log(error);
        },
      });
  }

  private getRoles(field: IFormField | undefined) {
    if (!field) return;

    this.isLoadingOptions = field.name;
    this.usersService.getRoles()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.isLoadingOptions = '';
          field.hasRequestedOptions = true;

          if (response?.data?.length) {
            this.setFieldOptionsFromResponseData(field, response);
          }
        },
        error: (error) => {
          this.isLoadingOptions = '';
          console.log(error);
        },
      });
  }

  private getTimezones(field: IFormField | undefined) {
    if (!field) return;

    this.isLoadingOptions = field.name;
    this.configurationsService.getTimezones()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.isLoadingOptions = '';
          field.hasRequestedOptions = true;

          if (response?.data?.[0]?.attributes?.message) {
            field.options = [];
            Object.values(response.data[0].attributes.message).forEach((value: any) => {
              field.options?.push({ label: value, value });
            })
          }
        },
        error: (error) => {
          this.isLoadingOptions = '';
          console.log(error);
        },
      });
  }

  private initForm() {
    this.fields?.forEach((field: IFormField) => {
      let initialValue: any = '';

      switch (field.type) {
        case 'boolean': initialValue = false; break;
        case 'number': initialValue = 1; break;
        case 'phone':
        case 'text':
        case 'wysiwyg': initialValue = ''; break;
      }

      const formControl = new FormControl(initialValue);
      if (field.required) {
        formControl.setValidators(Validators.required);
      }

      this.formGroup.addControl(field.name, formControl);
    });

    this.formGroup.addControl('pref_change_log_subscription', new FormControl(false));
    this.formGroup.addControl('pref_incident_subscription', new FormControl(false));
    this.observeFormValueChanges();
  }

  private observeFormValueChanges() {
    this.formGroup.valueChanges.subscribe(() => {
      this.validatePassword();
    });
  }

  private observeLoaderService() {
    this.loaderService.loaderVisibleSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.isLoaderVisible = value;
        }
      });
  }

  private prepareUserPayload() {
    const attributes = { ...this.formGroup.value };
    delete(attributes.account);
    delete(attributes.price_tier);
    delete(attributes.role);

    if (!attributes.password) {
      delete(attributes.password);
      delete(attributes.confirm_password);
    }

    const relationships = {
      account: {
        data: {
          id: this.formGroup.value.account,
          type: 'accounts',
        }
      },
      price_tier: {
        data: {
          id: this.formGroup.value.price_tier,
          type: 'price_tiers',
        }
      },
      role: {
        data: {
          id: this.formGroup.value.role,
          type: 'roles',
        }
      }
    }

    const userPanel = {
      data: {
        type: 'user_panels',
        attributes: {
          ...this.user?.relationships?.user_panel,
          bypass_ip_restriction: this.user?.relationships?.user_panel?.attributes?.bypass_ip_restriction ?? false,
          invited: this.user?.relationships?.user_panel?.attributes?.invited ?? false,
        }
      }
    }

    return new UserUpdateDto(
      this.user.id,
      this.user.type,
      attributes,
      relationships,
      userPanel
    );
  }

  private setForm() {
    this.formGroup.get('active')?.setValue(this.user.attributes.active, { emitEvent: false });
    this.formGroup.get('email')?.setValue(this.user.attributes.email, { emitEvent: false });
    this.formGroup.get('extension')?.setValue(this.user.attributes.extension, { emitEvent: false });
    this.formGroup.get('firstname')?.setValue(this.user.attributes.firstname, { emitEvent: false });
    this.formGroup.get('home_phone')?.setValue(this.user.attributes.home_phone, { emitEvent: false });
    this.formGroup.get('job_title')?.setValue(this.user.attributes.job_title, { emitEvent: false });
    this.formGroup.get('lastname')?.setValue(this.user.attributes.lastname, { emitEvent: false });
    this.formGroup.get('middlename')?.setValue(this.user.attributes.middlename, { emitEvent: false });
    this.formGroup.get('notes')?.setValue(this.user.attributes.notes, { emitEvent: false });
    this.formGroup.get('mobile_phone')?.setValue(this.user.attributes.mobile_phone, { emitEvent: false });
    this.formGroup.get('office_phone')?.setValue(this.user.attributes.office_phone, { emitEvent: false });
    this.formGroup.get('otp_enabled')?.setValue(this.user.attributes.otp_enabled, { emitEvent: false });
    this.formGroup.get('pref_change_log_subscription')?.setValue(this.user.attributes.pref_change_log_subscription, { emitEvent: false });
    this.formGroup.get('pref_incident_subscription')?.setValue(this.user.attributes.pref_incident_subscription, { emitEvent: false });
    this.formGroup.get('primary')?.setValue(this.user.attributes.primary, { emitEvent: false });
    this.formGroup.get('title_prefix')?.setValue(this.user.attributes.title_prefix, { emitEvent: false });
    this.formGroup.get('vip')?.setValue(this.user.attributes.vip, { emitEvent: false });

    const home_phone = this.fields.find((field) => field.name == 'home_phone');
    if (home_phone) {
      home_phone.value = this.user.attributes.home_phone;
    }

    const mobile_phone = this.fields.find((field) => field.name == 'mobile_phone');
    if (mobile_phone) {
      mobile_phone.value = this.user.attributes.mobile_phone;
    }

    const office_phone = this.fields.find((field) => field.name == 'office_phone');
    if (office_phone) {
      office_phone.value = this.user.attributes.office_phone;
    }

    const accountsField = this.fields.find((field) => field.name == 'account');
    if (this.user.relationships?.account?.id && accountsField && !accountsField.options?.length) {
      accountsField?.options?.push({
        label: this.user.relationships!.account!.attributes!.title,
        value: this.user.relationships!.account!.id,
      });
    }
    this.formGroup.get('account')?.setValue(this.user.relationships?.account?.id, { emitEvent: false });

    const priceTiersField = this.fields.find((field) => field.name == 'price_tier');
    if (this.user.relationships?.price_tier?.id && priceTiersField && !priceTiersField?.options?.length) {
      priceTiersField?.options?.push({
        label: this.user.relationships!.price_tier!.attributes!.title,
        value: this.user.relationships!.price_tier!.id,
      });
    }
    this.formGroup.get('price_tier')?.setValue(this.user.relationships?.price_tier?.id, { emitEvent: false });

    const rolesField = this.fields.find((field) => field.name == 'role');
    if (this.user.relationships?.role?.id && rolesField && !rolesField?.options?.length) {
      rolesField?.options?.push({
        label: this.user.relationships!.role!.attributes!.title,
        value: this.user.relationships!.role!.id,
      });
    }
    this.formGroup.get('role')?.setValue(this.user.relationships?.role?.id, { emitEvent: false });

    const timeZonesField = this.fields.find((field) => field.name == 'time_zone');
    if (this.user.attributes.time_zone && timeZonesField && !timeZonesField?.options?.length) {
      timeZonesField?.options?.push({
        label: this.user.attributes.time_zone,
        value: this.user.attributes.time_zone,
      });
    }
    this.formGroup.get('time_zone')?.setValue(this.user.attributes.time_zone, { emitEvent: false });

    this.formGroup.updateValueAndValidity();
  }

  private setFieldOptionsFromResponseData(field: IFormField | undefined, response: any) {
    if (!field) return;

    field.options = response.data.map((item: any) => {
      return {
        label: item.attributes.title,
        value: item.id,
      }
    });

    field.options?.sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  private validatePassword() {
    const passwordField = this.formGroup.get('password');
    const confirmPasswordField = this.formGroup.get('confirm_password');

    if (passwordField?.value.length > 0 && passwordField?.value.length < 6) {
      passwordField?.setErrors({ customError: true });
      passwordField?.markAsTouched();
      this.formGroup.setErrors({ passwordLengthError: true });
      return;
    }

    if (passwordField?.value && (passwordField?.value !== confirmPasswordField?.value)) {
      passwordField?.setErrors({ customError: true });
      passwordField?.markAsTouched();
      confirmPasswordField?.setErrors({ customError: true });
      confirmPasswordField?.markAsTouched();
      this.formGroup.setErrors({ passwordMismatchError: true });
      return;
    }

    passwordField?.setErrors(null);
    confirmPasswordField?.setErrors(null);
    this.formGroup.setErrors(null);
  }
}
