import {ObjectData} from "../objectData";
import {ChangelogApprovalBoardAttributes} from "./changelogApprovalBoardAttributes";
import {ChangelogApprovalBoardRelationships} from "./changelogApprovalBoardRelationships";

export class ChangelogApprovalBoard extends ObjectData<ChangelogApprovalBoardAttributes, ChangelogApprovalBoardRelationships>{
  constructor(data: ObjectData<ChangelogApprovalBoardAttributes, ChangelogApprovalBoardRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = ChangelogApprovalBoard.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ChangelogApprovalBoardRelationships {
    let relationships = new ChangelogApprovalBoardRelationships();

    relationships.agent = ChangelogApprovalBoard.normalizeUserRelation(relationObject, included, 'agent');

    return relationships;
  }
}
