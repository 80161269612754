import {ObjectData} from "../objectData";
import {ChangelogAttributes} from "./changelogAttributes";
import {ChangelogRelationships} from "./changelogRelationships";
import {TicketRelationAttributes, TicketRelationRelationships} from "./ticketRelation";
import {ChangeStatusRelationAttributes, ChangeStatusRelationRelationships} from "./changeStatus";
import {ChangeRiskLevelRelationAttributes, ChangeRiskLevelRelationRelationships} from "./changeRiskLevel";
import {ChangeTypeRelationAttributes, ChangeTypeRelationRelationships} from "./changeType";
import {UserRelationAttributes, UserRelationRelationships} from "../user/userRelation";
import { ChangelogApprovalBoardRelationAttributes, ChangelogApprovalBoardRelationRelationships } from "./changelogApprovalBoardRelation";


export class Changelog  extends ObjectData<ChangelogAttributes, ChangelogRelationships>{
  constructor(data: ObjectData<ChangelogAttributes, ChangelogRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = Changelog.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ChangelogRelationships {
    let relationships = new ChangelogRelationships();

    relationships.account = Changelog.normalizeAccountRelation(relationObject, included);
    relationships.creator = Changelog.normalizeUserRelation(relationObject, included, 'creator');
    relationships.tickets = Changelog.normalizeMultipleRelation<TicketRelationAttributes, TicketRelationRelationships>(relationObject, included, 'tickets');
    relationships.change_status = Changelog.normalizeSingleRelation<ChangeStatusRelationAttributes, ChangeStatusRelationRelationships>(relationObject, included, 'change_status');
    relationships.change_risk_level = Changelog.normalizeSingleRelation<ChangeRiskLevelRelationAttributes, ChangeRiskLevelRelationRelationships>(relationObject, included, 'change_risk_level');
    relationships.change_type = Changelog.normalizeSingleRelation<ChangeTypeRelationAttributes, ChangeTypeRelationRelationships>(relationObject, included, 'change_type');
    relationships.assignees = Changelog.normalizeMultipleRelation<UserRelationAttributes, UserRelationRelationships>(relationObject, included, 'assignees');
    relationships.agent_approvers = Changelog.normalizeMultipleRelation<UserRelationAttributes, UserRelationRelationships>(relationObject, included, 'agent_approvers');
    relationships.board_approvers = Changelog.normalizeMultipleRelation<ChangelogApprovalBoardRelationAttributes, ChangelogApprovalBoardRelationRelationships>(relationObject, included, 'board_approvers');

    return relationships;
  }

}
