{
  "data": [
    {
      "id": 1,
      "attributes": {
        "model_associations": [
          {
            "name": "account",
            "mapping_name": "account",
            "label": "Account",
            "available_as_filter": [
              "invoice_list"
            ],
            "available_as_column": [
              "invoice_list"
            ],
            "attributes": [
              {
                "key": "id",
                "type": "select",
                "label": "Title",
                "source": "API",
                "action": "accounts/list",
                "sort": "title",
                "available_as_filter": [
                  "invoice_list"
                ],
                "available_as_column": []
              },
              {
                "key": "title",
                "type": "string",
                "label": "Title",
                "available_as_filter": [],
                "available_as_column": [
                  "invoice_list"
                ]
              }
            ]
          },
          {
            "name": "invoice_status",
            "mapping_name": "invoice_status",
            "label": "Status",
            "available_as_filter": [
              "invoice_list"
            ],
            "available_as_column": [
              "invoice_list"
            ],
            "attributes": [
              {
                "key": "id",
                "type": "select",
                "label": "Status",
                "source": "API",
                "action": "invoice_statuses",
                "sort": "title",
                "available_as_filter": [
                  "invoice_list"
                ],
                "available_as_column": []
              }
            ]
          }
        ],
        "model_attributes": [
          {
            "key": "uniq_number",
            "type": "text",
            "label": "Invoice Number",
            "sort": "uniq_number",
            "available_as_filter": [
              "invoice_list"
            ],
            "available_as_column": [
              "invoice_list"
            ]
          }
        ],
        "model_custom_attributes": []
      },
      "type": "invoice_available_filters"
    }
  ],
  "link": {},
  "meta": {}
}