import { ObjectData } from '../objectData';

export interface ExtraEmailAttributes {
  email: string;
}

export class ExtraEmail extends ObjectData<ExtraEmailAttributes, {}> {
  constructor(data: ObjectData<ExtraEmailAttributes, {}>, included: Object[]) {
    super(data, included);
  }
}
