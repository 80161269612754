import { RelationshipData } from "../relationshipData";
import { AccountRelationAttribute } from "../account/accountRelationAttribute";
import { UploadRelationAttribute } from "./uploadRelationAttribute";
import { StatusRelationAttribute } from "./statusRelationAttribute";
import { TypeRelationAttribute } from "./typeRelationAttribute";
import { ManufacturerRelationAttribute } from "./manufacturerRelationAttribute";
import { UserRelationAttributes } from "../user/userRelation";
import { TicketRelationAttribute } from "./ticketRelationAttribute";
import { AssetResourceAttributes } from "./assetResourceAttributes";
import { KaseyaDetailRelationAttributes } from "./KaseyaDetailRelationAttributes";

export class AssetResourceRelationships {
  account?: RelationshipData<AccountRelationAttribute>;
  uploads?: RelationshipData<UploadRelationAttribute>[];
  asset_resource_status?: RelationshipData<StatusRelationAttribute>;
  asset_resource_type?: RelationshipData<TypeRelationAttribute>;
  asset_resource_manufacturer?: RelationshipData<ManufacturerRelationAttribute>;
  contact?: RelationshipData<UserRelationAttributes>;
  managed_by?: RelationshipData<UserRelationAttributes>;
  tickets?: RelationshipData<TicketRelationAttribute>[];
  related_asset_resources?: RelationshipData<AssetResourceAttributes>[];
  kaseya_detail?: RelationshipData<KaseyaDetailRelationAttributes>;
}
