export interface ExtraEmailCreateAttributesDto {
  email: string;
}

export interface ExtraEmailCreateRelationshipsDto {
  user: {
    data: {
      id: number;
      type: string;
    }
  }
}

export class ExtraEmailCreateDto {
  data: {
    attributes: ExtraEmailCreateAttributesDto;
    relationships: ExtraEmailCreateRelationshipsDto;
  }
  type: string;

  constructor(email: string, userId: number | string) {
    this.type = 'extra_email',
    this.data = {
      attributes: { email },
      relationships: {
        user: {
          data: {
            id: +userId,
            type: 'contacts'
          }
        }
      }
    }
  }
}
