import { Component, DestroyRef, OnInit } from '@angular/core';
import { AssetResource } from "src/app/utilities/models/assetResource/assetResource";
import { AssetResourcesService } from "src/app/services/assetResources.service";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { FiltersService } from "src/app/services/filters.service";
import { SavedFilter } from "src/app/utilities/models/assetResource/savedFilter";
import { DocumentCount, DocumentCountResponse } from "src/app/utilities/models/documentCount";
import { AvailableFilterDetail } from "src/app/utilities/models/filters/availableFilterDetail";
import { FilterHelper } from "src/app/utilities/helpers/filterHelper"
import { FormGroup } from "@angular/forms";
import { FilterDropdownOption } from "src/app/utilities/models/filters/filterDropdownOption";
import { FilterAggregationOption } from "src/app/utilities/models/filters/filterAggregationOption";
import { SortParam } from "src/app/utilities/models/parameters/sortParam/sortParam";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UsersService } from 'src/app/services/users.service';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import {Subject} from "rxjs";
import {NzTableQueryParams} from "ng-zorro-antd/table";

@Component({
  selector: 'app-asset-list',
  templateUrl: './assetList.component.html',
  styleUrls: ['./assetList.component.scss']
})
export class AssetListComponent implements OnInit {
  static pageName: string = 'asset_resource_list';
  static filterLabel: { [key: string]: string } = {
    'title': 'title contains',
    'serial_number': 'serial number contains',
    'model_number': 'model number contains',
    'purchase_date': 'purchased between',
    'warranty_expiration_date': 'warranty',
    'asset_resource_types.id': 'type is',
    'asset_resource_statuses.id': 'status is',
    'asset_resource_manufacturers.id': 'manufacturer is',
    'accounts.id': 'account is'
  };

  static assetResourceViews: SavedFilter[] = [
    new SavedFilter('active', [new ReportParam('warranty_expiration_date', ['gte'], [FilterHelper.getDateNowAsString()])], 9),
    new SavedFilter('expiring', [new ReportParam('warranty_expiration_date', ['gte'], [FilterHelper.getXDaysAheadAsString(10)])], 10),
    new SavedFilter('expired', [new ReportParam('warranty_expiration_date', ['lte'], [FilterHelper.getDateNowAsString()])], 11),
    new SavedFilter('unknown', [new ReportParam('warranty_expiration_date', ['empty'], [])], 12)
  ];

  loading: boolean = false;
  pageIndex: number = 1;
  pageSize: number = 20;
  sort: SortParam = { order: undefined, attribute: undefined };
  total: number;
  totalPages: number;
  availableFilterOptions: FilterDropdownOption[] = [];
  filtersFormGroup: FormGroup;
  filtersCountFormGroup: number = 0;
  filterSelectOptionsByKey: { [key: string]: FilterAggregationOption[] } = {}
  assetResources: AssetResource[] = [];
  addFilterEnabled: boolean = false;
  hasAssetUpdatePermission: Permission | undefined;
  filtersResource: 'asset_resources';

  initialFetchDone: boolean = false;
  observeSelectedParamsWasSet: boolean = false;
  availableFiltersSet: boolean = false;
  availableFiltersSet$ = new Subject<boolean>();

  constructor(private assetResourcesService: AssetResourcesService,
              private filterService: FiltersService,
              private destroyRef: DestroyRef,
              private userService: UsersService) {
  }

  ngOnInit() {
    //this.filterService.resetFilters();
    this.filtersFormGroup = new FormGroup({});
    //this.getAssetResources(this.pageSize, this.pageIndex);
    this.setAssetResourcesViews();
    this.getAvailableFilters();

    this.hasAssetUpdatePermission = this.userService.findPermission(
      'Ticketing::AssetResource',
      'ticketing/operator/v1/asset_resources',
      'update'
    );

    this.availableFiltersSet$.subscribe({
      next: ( _value: boolean ) => {
        if (!this.availableFiltersSet) {
          this.initializeControlsByReportParams();

          if (!this.observeSelectedParamsWasSet) {
            this.observeSelectedReportParams();
          }
          this.availableFiltersSet = true;
        }
      }
    });
  }

  observeSelectedReportParams(): void {
    this.observeSelectedParamsWasSet = true;

    this.filterService.selectedReportParams$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (_selectedParams: { [key: string]: ReportParam[]}) => {
          this.getAssetResources();
        }
      })

    this.filterService.selectedReportParamsChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          this.calculateAllSelectControlsOptions(value);
        }
      })
  }

  private setAssetResourcesViews() {
    AssetListComponent.assetResourceViews.forEach((assetResourceView: SavedFilter) => {
      this.getAssetResourceViewData(assetResourceView);
    });
  }

  getAssetResources(pageSize: number = this.pageSize, pageIndex: number = 1) {
    this.loading = true;
    this.initialFetchDone = true;
    this.checkIfAddFilterIsEnabled();
    const reportParams = this.filterService.getSelectedFilters(this.filtersResource);
    //reset sort when having like report param operator
    if(this.sort.order && this.sort.attribute && reportParams.some((param: ReportParam) => param.operator[0] == 'like')){
      this.sort = { attribute: undefined, order: undefined };
    }
    //console.log('get asset resources:', reportParams);
    this.assetResourcesService.getAssetResources(pageSize, pageIndex, this.filterService.getSelectedFilters(this.filtersResource), this.sort)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          if (response.data) {
            this.assetResources = response.data.map((asset: any) => new AssetResource(asset, response.included));
          }
          this.loading = false;
          this.total = response.meta.total_count;
          this.totalPages = response.meta.total_pages;
        }
      })
  }

  onQueryParamsChange(params: NzTableQueryParams): void {
    const { pageSize, pageIndex, sort } = params;
    const currentSort = sort.find(item => item.value !== null);

    this.sort.attribute = (currentSort?.key) ? currentSort.key : 'title';
    this.sort.order = (currentSort?.value == 'ascend') ? 'asc' : 'desc';

    if (this.initialFetchDone) {
      this.getAssetResources(pageSize, pageIndex);
    }
  }

  onSelect(value: any, filter: AvailableFilterDetail) {
    this.pageIndex = 1
    if (value?.length == 0) {
      this.filterService.removeFilterFromSelectedParams(this.filtersResource, filter);
    } else {
      switch (filter.type) {
        case 'text':
        case 'string':
          if (value?.length > 2)
            this.filterService.putFilterToSelectedParams(this.filtersResource, filter, value);
          break;
        case 'select':
          if (value.includes('0') && value.length > 1) {
            this.filtersFormGroup.controls[filter.computed_key]?.setValue(this.handleEmptyValue(value));
          } else {
            this.filterService.putFilterToSelectedParams(this.filtersResource, filter, value);
          }
          break;
        case 'date': // handle date as simple dates without time
          const dates = [new Date(value[0]).toISOString().slice(0, 10), new Date(value[1]).toISOString().slice(0, 10)];
          this.filterService.putFilterToSelectedParams(this.filtersResource, filter, dates);
          break;
        default:
          this.filterService.putFilterToSelectedParams(this.filtersResource, filter, value);
          break;
      }
    }
  }

  /*
  If the 0 (empty) is added after other selection we need to remove
  all other selections, if any other selection is added after 0 (empty)
  we remove 0
  */
  handleEmptyValue(value: string[]): string[] {
    const zeroIndex = value.indexOf('0');
    return zeroIndex == 0 ? value.slice(1, value.length) : ['0'];
  }

  getOptions(filter: AvailableFilterDetail): void {
    if (filter.computed_key == 'warranty_expiration_date') {
      this.filterSelectOptionsByKey[filter.computed_key] = AvailableFilterDetail.getDateOptions();
    } else if (filter.aggregation_key) {
      this.filterService.getFilterOptionsWithAggs('asset_resources', filter.aggregation_key, filter.computed_key)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            this.filterSelectOptionsByKey[filter.computed_key] = FilterHelper.setAggregationsToSelectOptions(response, filter.aggregation_key);
          }
        })
    }
  }

  getAssetResourceViewData(assetResourceView: SavedFilter) {
    assetResourceView.loading = true;
    this.assetResourcesService.getAssetResourcesDocumentCount(assetResourceView.reportParams)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: DocumentCountResponse) => {
          assetResourceView.loading = false;
          assetResourceView.totalCount = DocumentCount.getCount(response);
        }
      })
  }

  applyAssetResourceView(assetResourceView: SavedFilter) {
    this.clearAllFilters(false);
    assetResourceView.reportParams.forEach((reportParam: ReportParam) => {
      this.addFilterControl(reportParam.key, assetResourceView.optionId);
      this.filterService.putReportParamsToSelectedParams(this.filtersResource, reportParam, assetResourceView.optionId);
    });
    this.pageIndex = 1;
    this.getAssetResources();
  }

  addFilterControl(key: string, selectedValue: any = null) {
    const filter: AvailableFilterDetail | null = this.filterService.getFilterDetails(key);
    if (!filter) return;

    this.filtersFormGroup.addControl(key, AvailableFilterDetail.getFormControlByType(filter.type));
    this.getOptions(filter);
    this.filtersFormGroup.controls[key].setValue(selectedValue);
    this.filtersCountFormGroup++;
    this.checkIfAddFilterIsEnabled();
  }

  removeFilterControl(filter: AvailableFilterDetail) {
    this.filtersFormGroup.removeControl(filter.computed_key);
    this.filtersCountFormGroup--;
    this.filterService.removeFilterFromSelectedParams(this.filtersResource, filter);
    this.checkIfAddFilterIsEnabled();
  }

  clearAllFilters(notifyChange: boolean = true) {
    this.filtersFormGroup = new FormGroup([]);
    this.filterService.clearAllFilters(this.filtersResource, notifyChange);
    this.filtersCountFormGroup = 0;
    this.checkIfAddFilterIsEnabled();
  }

  getFiltersByFormGroupControls(): AvailableFilterDetail[] {
    return Object.keys(this.filtersFormGroup.controls).map((key: string) => {
      return this.filterService.getFilterDetails(key);
    })
  }

  getFilterLabel(key: string, label: string): string {
    return AssetListComponent.filterLabel[key] || label;
  }

  getAvailableFilters() {
    this.filterService.getAvailableFilters('asset_resources', 'index', AssetListComponent.pageName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ((value: FilterDropdownOption[]) => {
            this.availableFilterOptions = value;
            this.availableFiltersSet$.next(true);
          }
        )
      });
  }

  calculateAllSelectControlsOptions(changedControlKey: string = '') {
    for (let control in this.filtersFormGroup.controls) {
      if (control != changedControlKey) {
        this.getOptions(this.filterService.getFilterDetails(control));
      }
    }
  }

  checkIfAddFilterIsEnabled() {
    this.addFilterEnabled = this.filtersCountFormGroup == this.filterService.getSelectedFilters(this.filtersResource)?.length;
  }

  get staticAssetResourceViews(): SavedFilter[] {
    return AssetListComponent.assetResourceViews;
  }

  private initializeControlsByReportParams() {
    this.filterService.getSelectedReportParams(this.filtersResource).forEach((reportParam) => {
      if(reportParam.key == 'warranty_expiration_date'){
        this.addFilterControl(reportParam.key, this.filterService.getCustomControlValue(reportParam.key));
      }else{
        this.addFilterControl(reportParam.key, reportParam.value);
      }
    })
  }
}
