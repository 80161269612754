export class NavFilterAggs {
  all: number;
  all_open: number;
  my_closed: number;
  my_open: number;

  constructor(response: any, _included: any[]) {
    this.all = response.data[0]?.attributes.all;
    this.all_open = response.data[0]?.attributes.all_open;
    this.my_closed = response.data[0]?.attributes.my_closed;
    this.my_open = response.data[0]?.attributes.my_open;
  }
}
