import { Component, computed, DestroyRef, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { FiltersService } from 'src/app/services/filters.service';
import { UsersService } from 'src/app/services/users.service';
import { AvailableFilterDetail } from 'src/app/utilities/models/filters/availableFilterDetail';
import { FilterAggregationOption } from 'src/app/utilities/models/filters/filterAggregationOption';
import { FilterDropdownOption } from 'src/app/utilities/models/filters/filterDropdownOption';
import { ReportParam } from 'src/app/utilities/models/parameters/reportParam/reportParam';
import { Permission } from 'src/app/utilities/models/permissions/permission';
import { User } from 'src/app/utilities/models/user/user';

interface INzTableSortOrder {
  order: string | null;
  attribute: string | null;
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit {
  availableFilterOptions: FilterDropdownOption[] = [];
  addFilterEnabled: boolean = true;
  filterLabels: { [key: string]: string } = {
    'active': 'are',
    'email': 'email contains',
    'fullname': 'name contains',
    'price_tiers.id': 'type is',
  };
  filtersFormGroup: FormGroup;
  filtersCountFormGroup: number = 0;
  filterSelectOptionsByKey: { [key: string]: FilterAggregationOption[] } = {};
  filtersResource = 'users';
  isLoading: boolean = false;
  loggedInUser: User;
  pageIndex: number = 1;
  pageSize: number = 20;
  searchSubject$ = new BehaviorSubject<{ q: string, filter: AvailableFilterDetail | undefined}>({
    q: '', filter: undefined
  });
  sort: INzTableSortOrder = {
    order: 'ascend',
    attribute: 'firstname'
  };
  tableColumns = [
    { key: 'firstname', label: 'First name' },
    { key: 'lastname', label: 'Last name' },
    { key: 'email', label: 'Email' },
    { key: 'role.title', label: 'Role' },
    { key: 'price_tier.title', label: 'Type' },
    { key: 'active', label: 'Active' },
  ];
  total: number = 0;
  users: User[] = [];
  userCreatePermission: Permission | undefined;
  userShowPermission = signal<Permission | undefined>(undefined);

  constructor(private destroyRef: DestroyRef,
              private filterService: FiltersService,
              private userService: UsersService) {}

  canViewUser = computed(() => {
    const userRole = this.loggedInUser?.relationships?.role?.attributes?.system_key ?? '';
    let canViewUser = !!(this.userShowPermission());

    if (['billing_contact_role'].includes(userRole)) canViewUser = false;

    return canViewUser;
  });

  ngOnInit() {
    this.filtersFormGroup = new FormGroup({});
    this.loggedInUser = this.userService.loggedInUser;
    this.userCreatePermission = this.userService.findPermission(
      'Digcore::Contact',
      'ticketing/operator/v1/contacts',
      'create'
    );
    this.userShowPermission.set(this.userService.findPermission(
      'Digcore::User',
      'ticketing/operator/v1/users',
      'show'
    ));

    this.getUsers();
    this.getAvailableFilters();
    this.subscribeToSearchString();
    this.subscribeToSelectedReportParamsChange();
    this.initializeControlsByReportParams();
  }

  getFilterLabel(key: string, label: string): string {
    return this.filterLabels[key] || label;
  }

  getFiltersByFormGroupControls(): AvailableFilterDetail[] {
    return Object.keys(this.filtersFormGroup.controls).map((key: string) => {
      return this.filterService.getFilterDetails(key);
    })
  }

  onClearAllFilters(notifyChange: boolean = true) {
    this.filtersFormGroup = new FormGroup([]);
    this.filterService.clearAllFilters(this.filtersResource, notifyChange);
    this.filtersCountFormGroup = 0;
    this.searchSubject$.next({ q: '', filter: undefined });
    this.checkIfAddFilterIsEnabled();
  }

  onAddFilterControl(key: string, selectedValue: any = null) {
    const filter: AvailableFilterDetail | null = this.filterService.getFilterDetails(key);

    if (!filter) return;

    if(!this.filtersFormGroup.controls[key]) {
      this.filtersFormGroup.addControl(key, AvailableFilterDetail.getFormControlByType(filter.type));
      this.filtersCountFormGroup++;
      this.getOptions(filter);
    }

    this.filtersFormGroup.controls[key].setValue(selectedValue);
    this.checkIfAddFilterIsEnabled();
  }

  onRemoveFilterControl(filter: AvailableFilterDetail) {
    this.filtersFormGroup.removeControl(filter.computed_key);
    this.filtersCountFormGroup--;
    this.filterService.removeFilterFromSelectedParams(this.filtersResource, filter);
    this.checkIfAddFilterIsEnabled();
  }

  onSearch(str: string, filter: AvailableFilterDetail) {
    this.getOptions(filter, str);
  }

  onSelect(value: any, filter: AvailableFilterDetail) {
    this.pageIndex = 1

    if (value?.length == 0) {
      this.filterService.removeFilterFromSelectedParams(this.filtersResource, filter);
      this.searchSubject$.next({ q: '', filter: undefined });
    } else {
      switch (filter.type) {
        case 'text':
        case 'string':
          this.searchSubject$.next({ q: value, filter });
          break;

        default:
          this.filterService.putFilterToSelectedParams(this.filtersResource, filter, value);
      }
    }
  }

  onPageIndexChange(index: number) {
    this.pageIndex = index;
    this.getUsers();
  }

  onPageSizeChange(size: number) {
    this.pageSize = size;
    this.getUsers();
  }

  onSortOrderChange(order: string | null, attribute: string) {
    this.sort.order = order ?? 'ascend';
    this.sort.attribute = attribute;

    if (order) {
      this.getUsers();
    }
  }

  private addFilterControl(key: string, selectedValue: any = null) {
    const filter: AvailableFilterDetail | null = this.filterService.getFilterDetails(key);

    if (!filter) return;

    if (!this.filtersFormGroup.controls[key]) {
      this.filtersFormGroup.addControl(key, AvailableFilterDetail.getFormControlByType(filter.type));
      this.filtersCountFormGroup++;
      this.getOptions(filter);
    }

    this.filtersFormGroup.controls[key].setValue(selectedValue);
    this.checkIfAddFilterIsEnabled();
  }

  private calculateAllSelectControlsOptions(changedControlKey: string = '') {
    for (let control in this.filtersFormGroup.controls) {
      if (control != changedControlKey) {
        this.getOptions(this.filterService.getFilterDetails(control));
      }
    }
  }

  private checkIfAddFilterIsEnabled() {
    this.addFilterEnabled = (this.filtersCountFormGroup == this.filterService.getSelectedFilters(this.filtersResource)?.length);
  }

  private getAvailableFilters() {
    this.filterService.getAvailableFilters('users_list', 'index', 'user_list')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ((value: FilterDropdownOption[]) => {
            this.availableFilterOptions = value;
          }
        )
      });
  }

  private getOptions(filter: AvailableFilterDetail, str?: string) {
    if (!filter.action) return;

    this.filterService.getFilterOptions(filter.action, str)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.filterSelectOptionsByKey[filter.computed_key] = response.data.map((row: any) => new FilterAggregationOption(row.id, row.attributes.title, 0));
        }
      })
  }

  private getUsers() {
    const sortOrder = (this.sort.order === 'ascend') ? 'asc' : 'desc';
    const reportParams: ReportParam[] = [
      { key: 'type', operator: ['in'], value: ['Digcore::AccountAgent', 'Digcore::Contact'] }
    ];

    const params = this.filterService.getSelectedFilters(this.filtersResource);
    params.map((param) => {
      let operator: string[] = ['eq'];

      if (Array.isArray(param.value)) {
        operator = ['in'];
      }

      if (param.operator[0] == 'like') {
        operator = ['like'];
      }

      reportParams.push(new ReportParam(param.key, operator, param.value.toString()));
    });

    this.isLoading = true;
    this.userService.getAccountUsers({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      reportParams,
      sort: {
        attribute: this.sort?.attribute ?? undefined,
        order: sortOrder,
      },
    })
    .subscribe({
      next: (response: any) => {
        this.isLoading = false;
        this.users = response?.data?.map((item: any) => new User(item, response?.included ?? [])) ?? [];
        this.total = response?.meta?.total_count ?? this.users.length;
        this.checkIfAddFilterIsEnabled();
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  private initializeControlsByReportParams() {
    this.filterService.getSelectedReportParams(this.filtersResource).forEach((reportParam) => {
      this.addFilterControl(reportParam.key, reportParam.value);
    })
  }

  private subscribeToSearchString() {
    this.searchSubject$
      .pipe(
        debounceTime(500),
        skip(1), // this is to avoid the initial double call from init method
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: ({ q, filter }) => {
          const query = q.trim();

          if (query.length > 2 && filter) {
            this.filterService.putFilterToSelectedParams(this.filtersResource, filter, query);
          }
        },
        error: (error) => {
          console.log(error);
        }
      })
  }

  private subscribeToSelectedReportParamsChange() {
    this.filterService.selectedReportParamsChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value) => {
          this.getUsers();
          this.getAvailableFilters();
          this.calculateAllSelectControlsOptions(value);
        }
      })
  }
}
