<nz-layout class="users-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'users']">User Management</a></nz-breadcrumb-item>
        @if (user && !isLoading) {
          <nz-breadcrumb-item>{{ user.fullname() }}</nz-breadcrumb-item>
        }
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','users']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    @if (isLoading) {
      <nz-spin nzSimple></nz-spin>
    }

    @if (user && !isLoading) {
      <div nz-row nzJustify="center">
        <div nz-col>
          <h1>{{ user.fullname() }}</h1>
        </div>
      </div>

      @if (hasRelatedAggs()) {
        <div nz-row nzJustify="center">
          <div nz-col [nzSpan]="24" class="related-aggs">
            <nz-divider></nz-divider>

            <div class="data-wrapper">
              <div class="group">
                <span><b>Tickets</b> (Requester)</span>
                <span class="pill is-open">Open <strong>{{userRelatedAggs()?.requester_tickets?.in_progress ?? 0}}</strong></span>
                <span class="pill is-closed">Completed <strong>{{userRelatedAggs()?.requester_tickets?.completed ?? 0}}</strong></span>
              </div>

              <div class="group">
                <span><b>Tickets</b> (Watcher)</span>
                <span class="pill is-open">Open <strong>{{userRelatedAggs()?.watcher_tickets?.in_progress ?? 0}}</strong></span>
                <span class="pill is-closed">Completed <strong>{{userRelatedAggs()?.watcher_tickets?.completed ?? 0}}</strong></span>
              </div>
            </div>
          </div>
        </div>
      }

      @if (formGroup) {
        <div nz-row>
          <div nz-col [nzSpan]="24" class="form-wrapper">
            <nz-divider></nz-divider>

            <form nz-form
                  nzLayout="vertical"
                  [formGroup]="formGroup"
                  (ngSubmit)="onSubmit()">
              <h2 nz-typography class="form-fields-group-header">User details</h2>
              <nz-divider></nz-divider>

              @for (field of fields; track field.name) {
                @if (field.name == 'email') {
                  <h2 nz-typography class="form-fields-group-header">Security</h2>
                  <nz-divider></nz-divider>
                }

                <nz-form-item [ngClass]="'form-item-' + field.name">
                  <nz-form-label [nzFor]="field.name" [nzRequired]="field.required">
                    {{field.label}}
                  </nz-form-label>
                  <nz-form-control>
                    @switch (field.type) {
                      @case ('boolean') {
                        <nz-switch [formControlName]="field.name"></nz-switch>
                      }
                      @case ('password') {
                        <input nz-input [formControlName]="field.name" [name]="field.name" type="password" />
                      }
                      @case ('phone') {
                        <app-phone-input [phone]="field.value" (phoneChanged)="onSetPhoneNumber($event, field.name)" />
                      }
                      @case ('selector') {
                        <nz-select [formControlName]="field.name"
                                   [nzAllowClear]="field?.isClearable ?? null"
                                   [nzLoading]="isLoadingOptions === field.name"
                                   (nzOpenChange)="onSelectOpenChange($event, field.name)">
                          @for (option of field.options; track option.label) {
                            <nz-option [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                          }
                        </nz-select>
                      }
                      @case ('text') {
                        <input nz-input [formControlName]="field.name" [name]="field.name" />

                        @if (field.name === 'email') {
                          <extra-emails [user]="user" (extraEmailsUpdated)="getUser(user.id, user.type)" />
                        }
                      }
                      @case ('wysiwyg') {
                        <froala-editor [formControlName]="field.name" />
                      }
                    }
                  </nz-form-control>
                </nz-form-item>
              }

              <h2 nz-typography class="form-fields-group-header">Notifications</h2>
              <nz-divider></nz-divider>

              <nz-form-item ngClass="form-item-pref_incident_subscription">
                <nz-form-label>Incident</nz-form-label>
                <nz-form-control>
                  <nz-switch formControlName="pref_incident_subscription"></nz-switch>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item ngClass="form-item-pref_change_log_subscription">
                <nz-form-label>Change</nz-form-label>
                <nz-form-control>
                  <nz-switch formControlName="pref_change_log_subscription"></nz-switch>
                </nz-form-control>
              </nz-form-item>

              <div class="actions-container">
                <button nz-button
                        type="submit"
                        nzType="primary"
                        nzShape="round"
                        nzSize="large"
                        [disabled]="formGroup && (!formGroup.valid || !formGroup.dirty)">
                  Save & Close
                </button>
              </div>
            </form>
          </div>
        </div>
      }
    }
  </nz-content>
</nz-layout>

<custom-loader *ngIf="isLoaderVisible"></custom-loader>
