<nz-layout class="tickets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'dashboard']">Dashboard</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="post">{{ post.attributes.title }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col nzSpan="12">
        <a [routerLink]="['/app','dashboard']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </a>
      </div>
      <div nz-col nzSpan="12" *ngIf="editPermission" class="text-right">
        <a [routerLink]="['/app','news']" class="edit-link">
          <span class="txt-grey">See All News</span>
          <i nz-icon nzType="right" [nzTheme]="'outline'"></i>
        </a>
      </div>
    </div>
    <div nz-row nzJustify="center" *ngIf="loading">
      <div nz-col nzSpan="24" class="text-center">
        <nz-spin nzSimple [nzSize]="'small'"></nz-spin>
      </div>
    </div>
    <div class="post-container" *ngIf="post && !loading">
      <div [class]="post.attributes.featured_image_url ? 'post-image' : 'post-image empty'">
        <img [src]="post.attributes.featured_image_url" alt=""/>
        <button *ngIf="editPermission && post?.id"
                nz-button
                nzShape="round"
                nzType="primary"
                [routerLink]="['/app','news', post.id, 'edit']">
          Edit Post <span nz-icon nzType="edit" [nzTheme]="'outline'"></span>
        </button>
      </div>
      <nz-divider></nz-divider>
      <div class="post-author">
        By {{ post.attributes.author_fullname }}
      </div>
      <div class="post-title">
        {{ post.attributes.title }}
      </div>
      <div class="post-content" [innerHtml]="post.attributes.content"></div>
    </div>
  </nz-content>
</nz-layout>