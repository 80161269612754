import {Component, DestroyRef, OnInit} from '@angular/core';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ActivatedRoute, Params} from "@angular/router";
import {UsersService} from "src/app/services/users.service";
import {TZDate, tzOffset} from "@date-fns/tz";
import {format} from "date-fns";
import {ChangelogsService} from "src/app/services/changelogs.service";
import {ChangelogUpdate} from "src/app/utilities/models/changelog/changelogUpdate";
import {Changelog} from "src/app/utilities/models/changelog/changelog";
import {ChangelogApprovalDecision} from "src/app/utilities/models/changelog/changelogApprovalDecision";
import {ChangelogApprovalBoard} from "src/app/utilities/models/changelog/changelogApprovalBoard";

@Component({
  selector: 'app-changelog-show',
  templateUrl: './changelog-show.component.html',
  styleUrl: './changelog-show.component.scss'
})
export class ChangelogShowComponent implements OnInit {
  loading: boolean = false;
  changeUpdatesLoading: boolean = false;
  changelogApprovalBoardLoading: boolean = false;
  changelog: Changelog;
  changelogUpdates: ChangelogUpdate[];
  approvalDecisions: ChangelogApprovalDecision[];
  userTimezone: string | undefined;
  changelogApprovalBoards: ChangelogApprovalBoard[];

  constructor(private changelogService: ChangelogsService,
              private activatedRoute: ActivatedRoute,
              private userService: UsersService,
              private destroyRef: DestroyRef) {
  }

  get lastApprovalDecisions() {
    return this.approvalDecisions?.filter(decision => decision.attributes.last) || [];
  }

  ngOnInit(): void {
    this.userTimezone = this.userTimezone = (isNaN(tzOffset((this.userService.loggedInUser.attributes?.time_zone ?? ''), new Date())))
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : this.userService.loggedInUser.attributes.time_zone;
    this.activatedRoute.params.subscribe({
        next: (params: Params) => {
          this.getChangelog(params['id']);
          this.getChangelogUpdates(params['id']);
        }
      }
    );
  }

  getChangelog(id: number) {
    this.loading = true;
    this.changelogService.getChangelog(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.changelog = new Changelog(response.data, response.included);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      })
  }

  getChangelogUpdates(id: number) {
    this.changeUpdatesLoading = true;
    this.changelogService.getChangelogUpdates(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.changelogUpdates = response.data.map((row: any) => new ChangelogUpdate(row, response.included));
          this.changeUpdatesLoading = false;
          this.getChangelogDecisions(id);
          this.getChangeApprovalBoards();
        },
        error: () => {
          this.changeUpdatesLoading = false;
        }
      })
  }

  getChangeApprovalBoards() {
    this.changelogApprovalBoardLoading = true;
    this.changelogService.getChangeApprovalBoards()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.changelogApprovalBoards = response.data.map((row: any) => new ChangelogApprovalBoard(row, response.included));
          this.changelogApprovalBoardLoading = false;
        },
        error: () => {
          this.changelogApprovalBoardLoading = false;
        }
      })
  }

  getChangelogDecisions(id: number) {
    this.changelogService.getChangelogDecisions(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response: any) => {
          this.approvalDecisions = response.data.map((row: any) => new ChangelogApprovalDecision(row, response.included));
        }
      })
  }

  showDateInUsersTimezone(date: string) {
    return format(new TZDate(date, this.userTimezone), "MMM d yyyy, HH:mm");
  }
}
