<nz-layout class="users-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>User Management</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button">
          <i nz-icon nzType="left" [nzTheme]="'outline'"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-flex [nzVertical]="true" class="users-header">
      <div class="page-title">
        <span>&nbsp;</span>
        <h1>User Management <span *ngIf="!isLoading">({{ total }})</span></h1>
        <div>
          @if (userCreatePermission) {
            <button nz-button nzShape="round" class="btn-add-person" [routerLink]="['/app', 'users', 'new']">
              <span nz-icon nzType="plus"></span>
              Add new user
            </button>
          }
        </div>
      </div>

      <p>Manage users effortlessly with features like user list, detailed single-user pages, editing options, and the ability to create new users.</p>
    </div>

    <div nz-row class="filters-container">
      <div nz-col nzSpan="24">
        <form nz-form nzLayout="vertical" [formGroup]="filtersFormGroup" class="filters">
          <span *ngIf="filtersCountFormGroup == 0" class="no-filters-start-phrase">All people</span>
          <span *ngIf="filtersCountFormGroup >  0" class="clear-all-filters" (click)="onClearAllFilters()">Clear all</span>
          <span *ngIf="filtersCountFormGroup >  0" class="filters-start-phrase">Show me people that</span>

          @for (filter of getFiltersByFormGroupControls(); track $index) {
            <div class="asset-resource-filter">
              <span class="remove-filter" (click)="onRemoveFilterControl(filter)">
                <span nz-icon nzType="close"></span>
              </span>

              <span class="filter-prefix">
                {{ $index > 0 ? ' and' : ''}} {{ getFilterLabel(filter.computed_key, filter.label) }}
              </span>

              <div class="filter-control">
                <div class="filter-control-container">
                  <div *ngIf="filter.type == 'boolean'">
                    <nz-select [formControlName]="filter.computed_key"
                               nzPlaceHolder="Select"
                               nzShowSearch
                               [nzTokenSeparators]="[',']"
                               nzBorderless
                               [nzBackdrop]="false"
                               [nzShowArrow]="true"
                               nzServerSearch
                               (ngModelChange)="onSelect($event, filter)">
                      <nz-option [nzValue]="true" [nzLabel]="filter.label"></nz-option>
                      <nz-option [nzValue]="false" [nzLabel]="'not ' + filter.label"></nz-option>
                    </nz-select>
                  </div>

                  <div *ngIf="filter.type == 'select'">
                    <nz-select [formControlName]="filter.computed_key"
                               nzPlaceHolder="Select"
                               nzShowSearch
                               [nzTokenSeparators]="[',']"
                               nzBorderless
                               [nzBackdrop]="false"
                               [nzShowArrow]="true"
                               nzServerSearch
                               (nzOnSearch)="onSearch($event, filter)"
                               (ngModelChange)="onSelect($event, filter)">
                      <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                 [nzValue]="option.value"
                                 [nzLabel]="option.doc_count ? option.label + '('+option.doc_count+')' : option.label"></nz-option>
                    </nz-select>
                  </div>

                  <div *ngIf="['text', 'string'].includes(filter.type)">
                    <input nz-input
                           [formControlName]="filter.computed_key"
                           (ngModelChange)="onSelect($event, filter)"
                           nzBorderless
                           placeholder="type to search"/>
                  </div>
                </div>
              </div>
            </div>
          }

          @if (!addFilterEnabled) {
            <span class="add-filter-container disabled">
              <span class="tooltip" nz-tooltip [nzTooltipTitle]="'Please fill in all selected filters to add more'">
                <span class="add-filter-label">Add filter</span>
                <a class="add-filter">
                  <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                </a>
              </span>
            </span>
          }

          @if (addFilterEnabled && availableFilterOptions.length > 0) {
            <span class="add-filter-container"
                  nz-dropdown nzTrigger="click"
                  [nzDropdownMenu]="filtersAvailable"
                  [nzPlacement]="'bottomLeft'"
                  [nzDisabled]="!addFilterEnabled">
              <span class="add-filter-label" *ngIf="filtersCountFormGroup == 0">Add filter</span>
              <a class="add-filter">
                <span nz-icon nzType="plus"></span>
              </a>
            </span>

            <nz-dropdown-menu #filtersAvailable="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item *ngFor="let filter of availableFilterOptions" (click)="onAddFilterControl(filter.key)">
                  <span>{{ filter.label }}</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          }
        </form>
      </div>
    </div>

    <div nz-row class="list-container">
      <div nz-col nzSpan="24">
        <nz-table [nzData]="users"
                  [nzFrontPagination]="false"
                  [nzLoading]="isLoading"
                  [nzTotal]="total"
                  [nzPageSize]="pageSize"
                  [nzPageIndex]="pageIndex"
                  [nzBordered]="false"
                  [nzShowSizeChanger]="true"
                  (nzPageIndexChange)="onPageIndexChange($event)"
                  (nzPageSizeChange)="onPageSizeChange($event)">
          <thead>
            <tr>
              @for (column of tableColumns; track column.key) {
                <th [nzColumnKey]="column.key"
                    [nzSortDirections]="['ascend', 'descend']"
                    [nzSortFn]="true"
                    [nzSortOrder]="(sort.attribute == column.key ? sort.order : null)"
                    (nzSortOrderChange)="onSortOrderChange($event, column.key)">{{ column.label }}</th>
              }
            </tr>
          </thead>
          <tbody>
            @for (user of users; track user.id) {
              <tr>
                @if (canViewUser()) {
                  <td><a [routerLink]="['/app', 'users', user.id, user.type]">{{ user.attributes.firstname }}</a></td>
                  <td><a [routerLink]="['/app', 'users', user.id, user.type]">{{ user.attributes.lastname }}</a></td>
                } @else {
                  <td>{{ user.attributes.firstname }}</td>
                  <td>{{ user.attributes.lastname }}</td>
                }
                <td>{{ user.attributes.email }}</td>
                <td>{{ user.relationships?.role?.attributes?.title ?? '-' }}</td>
                <td>{{ user.relationships?.price_tier?.attributes?.title ?? '-' }}</td>
                <td align="center"><span nz-icon [nzType]="!!(user.attributes.active) ? 'check' : 'close'"></span></td>
              </tr>
            }
          </tbody>
        </nz-table>
      </div>
    </div>
  </nz-content>
</nz-layout>
