<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'incidents']">Incidents</a></nz-breadcrumb-item>
        <nz-breadcrumb-item *ngIf="incident?.attributes?.title">{{ incident.attributes.title }}</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','incidents']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <div nz-row nzJustify="center" *ngIf="loading">
        <nz-spin nzSimple></nz-spin>
      </div>
    </div>
    <div nz-row *ngIf="incident" nzJustify="center">
      <div nz-col nzSpan="24" [nzXl]="24" [nzXXl]="18">
        <div nz-row>
          <div nz-col nzSpan="16">
            <div class="incident-title">{{ incident.attributes.title }}</div>
          </div>
        </div>
        <div nz-row>
          <div nz-col nzSpan="16" class="left-content">
            <div nz-row>
              <div nz-col nzSpan="24">
                <div class="incident-timer">
                  <div class="time-values">
                    <div>
                      <div class="timer-value">{{ incidentTimer.days }}</div>
                      <div class="timer-label">days</div>
                    </div>
                    <div>
                      <div class="timer-value">{{ incidentTimer.hours }}</div>
                      <div class="timer-label">hours</div>
                    </div>
                    <div>
                      <div class="timer-value">{{ incidentTimer.minutes }}</div>
                      <div class="timer-label">minutes</div>
                    </div>
                    <div>
                      <div class="timer-value">{{ incidentTimer.seconds }}</div>
                      <div class="timer-label">seconds</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div nz-row>
              <div nz-col nzSpan="24">
                <div class="attribute">
                  <div class="attribute-label">Title</div>
                  <div class="attribute-value">{{ incident.attributes.title }}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Description</div>
                  <div class="attribute-value description" [innerHtml]="incident.attributes.description"></div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Status</div>
                  <div class="attribute-value">
                    <span class="incident-status" [style]="{ backgroundColor: incident.relationships?.incident_status?.attributes?.background_color, color: incident.relationships?.incident_status?.attributes?.color }">{{ incident.relationships?.incident_status?.attributes?.title }}</span>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Severity</div>
                  <div class="attribute-value">
                    <span class="incident-severity" [style]="{ backgroundColor: incident.relationships?.incident_severity?.attributes?.background_color, color: incident.relationships?.incident_severity?.attributes?.color }">{{ incident.relationships?.incident_severity?.attributes?.title }}</span>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Component</div>
                  <div class="attribute-value">{{ incident.relationships?.incident_component ? incident.relationships?.incident_component?.attributes?.title : '-' }}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">When</div>
                  <div class="attribute-value">{{ showDateInUsersTimezone(incident.attributes.started_at) }}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Resolved Time</div>
                  <div class="attribute-value">{{ incident.attributes.ended_at ? showDateInUsersTimezone(incident.attributes.ended_at) : '-' }}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Primary Contact</div>
                  <div class="attribute-value">{{ incident.relationships?.requester ? incident.relationships?.requester?.attributes?.fullname : '-'}}</div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Related Tickets</div>
                  <div class="attribute-value incident-tickets">
                    <div *ngFor="let ticket of incident.relationships?.tickets">
                      <a [routerLink]="['/app','support','tickets',ticket.attributes.code]">{{ ticket.attributes.code }} - {{ ticket.attributes.subject }}</a>
                    </div>
                    <div *ngIf="!incident.relationships?.tickets"><span>-</span></div>
                  </div>
                </div>
                <div class="attribute">
                  <div class="attribute-label">Created By</div>
                  <div class="attribute-value">{{ incident.relationships?.creator?.attributes?.fullname}}</div>
                </div>
              </div>

            </div>
          </div>
          <div nz-col nzSpan="8">
            <div class="incident-resolution-details post-incident-analysis" *ngIf="incident.attributes.resolution_description || incident.attributes.post_incident_analysis">
              <ng-container *ngIf="incident.attributes.post_incident_analysis">
                <div class="post-incident-analysis-label">
                  Post Incident Analysis (Recommendations)
                </div>
                <div class="post-incident-analysis-content" [innerHTML]="incident.attributes.post_incident_analysis"></div>
              </ng-container>

              <ng-container *ngIf="incident.attributes.resolution_description">
                <div class="incident-resolution-details-label">Resolution Details</div>
                <div class="resolution-detail">
                  <div class="resolution-detail-head" *ngIf="incident.relationships?.resolved_by">
                    <div class="resolution-detail-user">
                      <nz-avatar [nzSize]="16" nzIcon="user" [nzSrc]="incident.relationships?.resolved_by?.attributes?.avatar ?? ''"></nz-avatar>
                      {{ incident.relationships?.resolved_by?.attributes?.fullname }}
                    </div>
                    <div class="resolution-detail-date">
                      {{ incident.attributes.resolved_at | dateAgo }}
                    </div>
                  </div>
                  <div class="resolution-detail-content" [innerHTML]="incident.attributes.resolution_description"></div>
                </div>
              </ng-container>
            </div>
            <div class="incident-updates">
              <div class="incident-updates-label">Latest Updates</div>
              <div *ngIf="incidentUpdates && incidentUpdates.length == 0">No updates yet</div>
              <div *ngFor="let incidentUpdate of incidentUpdates" class="incident-update">
                <div class="incident-update-head">
                  <div class="incident-update-user">
                    <nz-avatar [nzSize]="16" nzIcon="user" [nzSrc]="incidentUpdate.relationships?.user?.attributes?.avatar ?? ''"></nz-avatar>
                    {{ incidentUpdate.relationships?.user?.attributes?.fullname }}
                  </div>
                  <div class="incident-update-date">
                    {{ incidentUpdate.attributes.created_at | dateAgo }}
                  </div>
                </div>
                <div class="incident-update-content" [innerHTML]="incidentUpdate.attributes.description"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
