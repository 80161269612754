import { IncludedData } from "../includedData";
import { ObjectData } from "../objectData";
import { RelationshipData } from "../relationshipData";
import { AccountRelationAttribute } from "../ticket/accountRelationAttribute";
import { RoleRelationAttribute } from "./roleRelationAttribute";
import { UserAttributes } from "./userAttributes";
import { ConversationParticipant } from "../ticket/conversation/conversationParticipant";
import { ConversationParticipantRelationships } from "../ticket/conversation/conversationParticipantRelationships";
import { EmailParticipantAttribute } from "../ticket/emailParticipantAttribute";
import { PriceTierRelationAttribute } from "./priceTierRelationAttribute";
import { UserPanelRelationAttribute } from "./userPanelRelationAttribute";
import { ExtraEmailRelationAttribute } from "./extraEmailRelationAttribute";


export class UserRelationships {
  account?: RelationshipData<AccountRelationAttribute>;
  extra_emails?: RelationshipData<ExtraEmailRelationAttribute>[];
  price_tier?: RelationshipData<PriceTierRelationAttribute>;
  role?: RelationshipData<RoleRelationAttribute>;
  user_panel?: RelationshipData<UserPanelRelationAttribute>;
}

export class User extends ObjectData<UserAttributes, UserRelationships> {

  constructor(data: ObjectData<UserAttributes, UserRelationships>, included?: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = User.normalizeRelation(data.relationships, included);
    }
  }

  public toConversationParticipant(): ConversationParticipant {
    const obj = {
      id: 0,
      type: "conversation_participants",
      attributes: { email: "" },
    };
    const included = [{
      id: this.id,
      type: 'users',
      attributes: { ...this.attributes }
    }];

    let cp = new ConversationParticipant(obj as ObjectData<EmailParticipantAttribute, ConversationParticipantRelationships>, included);
    cp.relationships = new ConversationParticipantRelationships();
    cp.relationships.participant = { id: this.id, type: 'users', attributes: { ...this.attributes } }

    return cp;
  }

  private static normalizeRelation(relationObject: Object, included: Object[]): UserRelationships {
    let userRelationships = new UserRelationships();

    userRelationships.account = User.normalizeAccountRelation(relationObject, included);
    userRelationships.extra_emails = User.normalizeExtraEmailsRelation(relationObject, included);
    userRelationships.price_tier = User.normalizePriceTierRelation(relationObject, included);
    userRelationships.role = User.normalizeRoleRelation(relationObject, included);
    userRelationships.user_panel = User.normalizeUserPanelRelation(relationObject, included);

    return userRelationships;
  }

  private static normalizeExtraEmailsRelation(relationObject: any, included: Object[]): RelationshipData<ExtraEmailRelationAttribute>[] {
    if (!included || !relationObject?.extra_emails?.data?.length) return [];

    return relationObject?.extra_emails?.data?.map((data: any) => {
      let extraEmailRelation = new RelationshipData<ExtraEmailRelationAttribute>();

      extraEmailRelation.id = +data.id;
      extraEmailRelation.type = data.type;
      let tmp = included.find((includedObject: any) => {
        return includedObject.type == extraEmailRelation.type &&
          includedObject.id == extraEmailRelation.id
      });

      extraEmailRelation.attributes = (tmp as IncludedData<ExtraEmailRelationAttribute>).attributes
      return extraEmailRelation;
    }) ?? [];
  }

  private static normalizeRoleRelation(relationObject: any, included: Object[]): RelationshipData<RoleRelationAttribute> | undefined {
    let roleRelation = new RelationshipData<RoleRelationAttribute>();
    if (!included || !relationObject?.role?.data?.id) return undefined;

    roleRelation.id = relationObject.role.data.id;
    roleRelation.type = relationObject.role.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == roleRelation.type &&
        includedObject.id == roleRelation.id;
    });
    roleRelation.attributes = (tmp as IncludedData<RoleRelationAttribute>).attributes

    return roleRelation;
  }

  private static normalizePriceTierRelation(relationObject: any, included: Object[]): RelationshipData<PriceTierRelationAttribute> | undefined {
    let priceTierRelation = new RelationshipData<PriceTierRelationAttribute>();
    if (!included || !relationObject?.price_tier?.data?.id) return undefined;

    priceTierRelation.id = relationObject.price_tier.data.id;
    priceTierRelation.type = relationObject.price_tier.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == priceTierRelation.type &&
        includedObject.id == priceTierRelation.id
    });
    priceTierRelation.attributes = (tmp as IncludedData<PriceTierRelationAttribute>).attributes

    return priceTierRelation;
  }

  private static normalizeUserPanelRelation(relationObject: any, included: Object[]): RelationshipData<UserPanelRelationAttribute> | undefined {
    let userPanelRelation = new RelationshipData<UserPanelRelationAttribute>();
    if (!included || !relationObject?.user_panel?.data?.id) return undefined;

    userPanelRelation.id = relationObject.user_panel.data.id;
    userPanelRelation.type = relationObject.user_panel.data.type;
    let tmp = included.find((includedObject: any) => {
      return includedObject.type == userPanelRelation.type &&
        includedObject.id == userPanelRelation.id
    });
    userPanelRelation.attributes = (tmp as IncludedData<UserPanelRelationAttribute>).attributes

    return userPanelRelation;
  }

  public fullname(): string {
    return this.attributes.firstname + ' ' + this.attributes.lastname;
  }

  public initials(): string {
    return this.attributes.firstname.charAt(0).toUpperCase() + this.attributes.lastname.charAt(0).toUpperCase();
  }
}
