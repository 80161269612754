<div class="add-service-modal-content">
  <div class="add-service-title">{{ getServiceMetricAddButtonTitle() }}</div>
  <div class="add-service-subtitle">Request</div>
  <form [formGroup]="addServiceForm">
    <div nz-row>
      <div nz-col [nzSpan]="24">
        <div class="repeatable-fields-container">
          <div class="repeatable-fields">
            <ng-container *ngFor="let item of fieldRows; let idx = index">
              <nz-divider *ngIf="idx > 0"></nz-divider>
              <div class="set-of-fields-container">
                <div class="set-of-fields">
                  <ng-container *ngFor="let control of formControls.repeatable">
                  <nz-form-item class="ticket-create-form-item" *ngIf="addServiceForm.controls[getControlName(control, item)]">
                    <nz-form-label [nzFor]="getControlName(control, item)" [nzRequired]="control.attributes.required">
                      {{ control.attributes.label }}
                    </nz-form-label>
                    <nz-form-control>
                      <!-- Input control -->
                      <input nz-input [placeholder]="control.attributes.placeholder || ''" [formControlName]="getControlName(control, item)" *ngIf="control.attributes.type == 'input'" data-lpignore="true"/>

                      <!-- Select control -->
                      <nz-select *ngIf="control.attributes.type == 'select'"
                                 [id]="control.attributes.name"
                                 [formControlName]="getControlName(control, item)">
                        <nz-option *ngFor="let option of control.attributes.options"
                                   [nzValue]="option.value"
                                   [nzLabel]="option.label"></nz-option>
                      </nz-select>

                      <!-- Textarea control -->
                      <textarea rows="4" nz-input [placeholder]="control.attributes.placeholder || ''" [formControlName]="getControlName(control, item)"  *ngIf="control.attributes.type == 'textarea'" data-lpignore="true"></textarea>

                      <!-- Wysiwyg control -->
                      <froala-editor *ngIf="control.attributes.type == 'wysiwyg'"
                                     [formControlName]="getControlName(control, item)"
                                     [options]="(control.attributes.placeholder) ? { placeholderText: control.attributes.placeholder } : null"></froala-editor>

                      <!-- Autocomplete -->
                      <input nz-input [placeholder]="control.attributes.placeholder || ''" [formControlName]="getControlName(control, item)" (input)="onAutoCompleteChange($event, control, item)" [nzAutocomplete]="auto"  *ngIf="control.attributes.type == 'autocomplete'"/>
                      <nz-autocomplete #auto>
                        <nz-auto-option [nzValue]="option.value" [nzLabel]="option.label" *ngFor="let option of filteredAutocompleteOptions[control.attributes.name]">
                          {{ option.label }}
                        </nz-auto-option>
                      </nz-autocomplete>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                </div>
                <div class="remove-set-action" (click)="removeSetOfControls(item)" *ngIf="idx > 0">
                  <span nz-icon nzType="minus" [nzTheme]="'outline'"></span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="add-set-of-fields-action">
          <div (click)="addSetOfControls()"><span nz-icon nzType="plus" [nzTheme]="'outline'"></span></div>
        </div>

        <nz-form-item class="ticket-create-form-item" *ngFor="let control of formControls.nonRepeatable">
          <nz-form-label [nzFor]="control.attributes.name">
            {{ control.attributes.label }}
          </nz-form-label>
          <nz-form-control>
            <!-- Input control -->
            <input nz-input [placeholder]="control.attributes.placeholder || ''" [formControlName]="control.attributes.name"  *ngIf="control.attributes.type == 'input'" data-lpignore="true"/>

            <!-- Select control -->
            <nz-select *ngIf="control.attributes.type == 'select'"
                       [id]="control.attributes.name"
                       [formControlName]="control.attributes.name">
              <nz-option *ngFor="let option of control.attributes.options"
                         [nzValue]="option.value"
                         [nzLabel]="option.label"></nz-option>
            </nz-select>

            <!-- Textarea control -->
            <textarea rows="4" nz-input [placeholder]="control.attributes.placeholder || ''" [formControlName]="control.attributes.name"  *ngIf="control.attributes.type == 'textarea'" data-lpignore="true"></textarea>

            <!-- Wysiwyg control -->
            <froala-editor *ngIf="control.attributes.type == 'wysiwyg'"
                           [formControlName]="control.attributes.name"
                           [options]="(control.attributes.placeholder) ? { placeholderText: control.attributes.placeholder } : null"></froala-editor>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control class="add-service-form-submit">
            <button nz-button type="submit" nzType="default" nzShape="round" nzSize="large" [nzLoading]="submittingRequest" (click)="closeModal()">Cancel</button>
            <button nz-button type="submit" nzType="primary" nzShape="round" nzSize="large" [nzLoading]="submittingRequest" (click)="onSubmit()" [disabled]="!addServiceForm.valid">Send Request</button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
<custom-loader *ngIf="loaderVisible"></custom-loader>
