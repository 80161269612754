<nz-layout class="users-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'users']">User Management</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Add New User</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','users']" class="back-button">
          <i nz-icon nzType="left"></i>
          <span class="txt-grey"> Back</span>
        </span>
      </div>
    </div>

    <div nz-row nzJustify="center">
      <div nz-col>
        <h1>Add New User</h1>
      </div>
    </div>

    @if (formGroup) {
      <div nz-row>
        <div nz-col [nzXs]="24" [nzSm]="18" [nzMd]="16" [nzLg]="12" class="form-wrapper">
          <nz-divider></nz-divider>

          <form nz-form
                nzLayout="vertical"
                [formGroup]="formGroup"
                (ngSubmit)="onSubmit()">
            @for (field of fields; track field.name) {
              <nz-form-item [ngClass]="'form-item-' + field.name">
                <nz-form-label [nzFor]="field.name" [nzRequired]="field.required">
                  {{field.label}}
                </nz-form-label>
                <nz-form-control>
                  @switch (field.type) {
                    @case ('phone') {
                      <app-phone-input [phone]="field.value" (phoneChanged)="onSetPhoneNumber($event, field.name)" />
                    }
                    @case ('selector') {
                      <nz-select [formControlName]="field.name"
                                 [nzLoading]="isLoadingOptions === field.name"
                                 (nzOpenChange)="onSelectOpenChange($event, field.name)">
                        @for (option of field.options; track option.label) {
                          <nz-option [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                        }
                      </nz-select>
                    }
                    @case ('text') {
                      <input nz-input [formControlName]="field.name" [name]="field.name" />
                    }
                  }
                </nz-form-control>
              </nz-form-item>
            }

            <div class="actions-container">
              <button nz-button
                      type="submit"
                      nzType="primary"
                      nzShape="round"
                      nzSize="large"
                      [disabled]="formGroup && !formGroup.valid">
                Save & Close
              </button>
            </div>
          </form>
        </div>
      </div>
    }
  </nz-content>
</nz-layout>

<custom-loader *ngIf="isLoaderVisible"></custom-loader>
