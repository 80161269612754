<nz-layout class="assets-layout">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item><a [routerLink]="['/app', 'solutions']">Apps</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Changes</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>
  <nz-content class="inner-layout">
    <div nz-row nzJustify="start">
      <div nz-col>
        <span [routerLink]="['/app','solutions']" class="back-button"><i nz-icon nzType="left" [nzTheme]="'outline'"></i><span
          class="txt-grey"> Back</span></span>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <div class="list-container">
          <div nz-row>
            <div nz-col nzSpan="24" class="incidents-top">
              <div>
                <h1>Changes</h1>
                <div>
                  Track, manage, and resolve your organization changes efficiently.
                </div>
              </div>
            </div>
          </div>

          <div nz-row class="filters-container">
            <div nz-col nzSpan="24">
              <!-- filters begin -->
              <form nz-form
                    nzLayout="vertical"
                    [formGroup]="filtersFormGroup">
                <div nz-row>
                  <div nz-col nzSpan="24" nzOffset="0" class="filters">
                    <span class="no-filters-start-phrase" *ngIf="filtersCountFormGroup == 0">All Changes</span>
                    <span class="clear-all-filters" *ngIf="filtersCountFormGroup > 0" (click)="clearAllFilters()">Clear all</span>
                    <span class="filters-start-phrase" *ngIf="filtersCountFormGroup > 0">Show me changes that</span>
                    <div *ngFor="let filter of getFiltersByFormGroupControls(); let i = index" class="incident-filter">
                      <span class="remove-filter" (click)="removeFilterControl(filter)">
                        <span nz-icon nzType="close" [nzTheme]="'outline'"></span>
                      </span>
                      <span class="filter-prefix">
                        {{ i > 0 ? ' and' : ''}} {{getFilterLabel(filter.computed_key, filter.computed_label) }}
                      </span>
                      <div class="filter-control">
                        <div class="filter-control-container">
                          <div *ngIf="filter.type == 'select' || filter.type == 'static_select'">
                            <nz-select [formControlName]="filter.computed_key"
                                       [nzPlaceHolder]="'Select'"
                                       nzShowSearch
                                       [nzTokenSeparators]="[',']"
                                       nzBorderless
                                       [nzBackdrop]="false"
                                       [nzShowArrow]="true"
                                       nzServerSearch
                                       (ngModelChange)="onSelect($event, filter)">
                              <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                         [nzValue]="option.value"
                                         [nzLabel]="option.doc_count ? option.label + '('+option.doc_count+')' : option.label"></nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="['text','string'].includes(filter.type)">
                            <input nz-input
                                   [formControlName]="filter.computed_key"
                                   (ngModelChange)="onSelect($event, filter)"
                                   nzBorderless
                                   placeholder="type to search"/>
                          </div>
                          <div *ngIf="filter.type == 'simplified_date'">
                            <nz-select [nzPlaceHolder]="'Select'"
                                       [formControlName]="filter.computed_key"
                                       nzBorderless
                                       [nzShowArrow]="true"
                                       [nzBackdrop]="false"
                                       (ngModelChange)="onSelect($event, filter)">
                              <nz-option *ngFor="let option of filterSelectOptionsByKey[filter.computed_key]"
                                         [nzValue]="option.value"
                                         [nzLabel]="option.label"></nz-option>
                            </nz-select>
                          </div>
                          <div *ngIf="filter.type == 'date'">
                            <nz-range-picker [nzShowTime]="false"
                                             [formControlName]="filter.computed_key"
                                             (ngModelChange)="onSelect($event, filter)"
                                             [nzAllowClear]="false">
                            </nz-range-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span class="add-filter-container disabled"
                          *ngIf="!addFilterEnabled">
                      <span class="tooltip" nz-tooltip [nzTooltipTitle]="'Please fill in all selected filters to add more'">
                        <span class="add-filter-label">Add filter</span>
                        <a class="add-filter">
                          <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                        </a>
                      </span>
                    </span>
                    <span class="add-filter-container"
                          nz-dropdown nzTrigger="click"
                          [nzDropdownMenu]="filtersAvailable"
                          [nzPlacement]="'bottomLeft'"
                          [nzDisabled]="!addFilterEnabled"
                          *ngIf="addFilterEnabled && availableFilterOptions.length > 0">
                        <span class="add-filter-label" *ngIf="filtersCountFormGroup == 0">Add filter</span>
                        <a class="add-filter">
                          <span nz-icon nzType="plus" [nzTheme]="'outline'"></span>
                        </a>
                      </span>
                    <nz-dropdown-menu #filtersAvailable="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item *ngFor="let filter of availableFilterOptions" (click)="addFilterControl(filter.key)">
                          <span>{{ filter.label }}</span>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </form>
              <!-- filters end -->
            </div>
          </div>
          <div nz-row class="list-description" *ngIf="availableFilterOptions">
            <div nz-col nzSpan="24">
              <nz-table [nzData]="changelogs"
                        [nzFrontPagination]="false"
                        [nzLoading]="loading"
                        [nzTotal]="total"
                        [nzPageSize]="pageSize"
                        [nzPageIndex]="pageIndex"
                        [nzBordered]="false"
                        (nzQueryParams)="onQueryParamsChange($event)">
                <thead>
                <tr>
                  <th nzColumnKey="title" [nzSortFn]="true">Title</th>
                  <th nzColumnKey="occurs_at" [nzSortFn]="true">When</th>
                  <th nzColumnKey="change_risk_level.title" [nzSortFn]="true">Risk Level</th>
                  <th nzColumnKey="change_status.title" [nzSortFn]="true">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let changelog of changelogs">
                  <td><a [routerLink]="['/app', 'changes', changelog.id]">{{ changelog.attributes.title }}</a></td>
                  <td>{{ changelog.attributes.occurs_at | date }}</td>
                  <td>
                    <span class="incident-severity" [style]="{ backgroundColor: changelog.relationships?.change_risk_level?.attributes?.background_color, color: changelog.relationships?.change_risk_level?.attributes?.color }">
                      {{ changelog.relationships?.change_risk_level?.attributes?.title ?? '-' }}
                    </span>
                  </td>
                  <td>
                    <span class="incident-severity" [style]="{ backgroundColor: changelog.relationships?.change_status?.attributes?.background_color, color: changelog.relationships?.change_status?.attributes?.color }">
                      {{ changelog.relationships?.change_status?.attributes?.title ?? '-' }}
                    </span>
                  </td>
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
