<nz-layout class="request-content">
  <nz-header class="main-layout-header">
    <mainHeader>
      <nz-breadcrumb breadcrumb nzSeparator="·">
        <nz-breadcrumb-item><a [routerLink]="['/app']">EMPIST 360</a></nz-breadcrumb-item>
        <nz-breadcrumb-item>Apps</nz-breadcrumb-item>
      </nz-breadcrumb>
    </mainHeader>
  </nz-header>

  <nz-content class="inner-layout">
    <div nz-row [nzGutter]="[12,12]">
      <div *ngIf="['Digcore::AccountAgent', 'Digcore::Contact'].includes(loggedInUser.attributes.type)"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="['/app', 'support']">
        <nz-card nzBorderless="true" class="card">
          <nz-card-meta
              [nzAvatar]="commentIcon"
              [nzTitle]="'Support'"
              nzDescription="Manage your requests and stay up-to-date with the latest communications from EMPIST.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="installedServicePermission && (loggedInUser.attributes.type == 'Digcore::Contact' || loggedInUser.attributes.type == 'Digcore::AccountAgent')"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('services') ? [] : ['/app', 'services']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'coming-soon': comingSoonFeatures.includes('services')}">
          <nz-card-meta
              [nzAvatar]="servicesIcon"
              [nzTitle]="comingSoonFeatures.includes('services') ? 'Services (coming soon)' : 'Services'"
              nzDescription="Discover the details of the active services and subscriptions provided to your organization.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="installedServiceCreatePermission"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('additional-services') ? [] : ['/app', 'additional-services']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent', 'coming-soon': comingSoonFeatures.includes('additional-services')}">
          <nz-card-meta
              [nzAvatar]="manuallyManagedServicesIcon"
              [nzTitle]="comingSoonFeatures.includes('additional-services') ? 'Additional Services (coming soon)' : 'Additional Services'"
              nzDescription="Create and manage services and metrics for EMPIST clients.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="invoicesPermission"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('invoices') ? [] : ['/app', 'invoices']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent', 'coming-soon': comingSoonFeatures.includes('invoices')}">
          <nz-card-meta
              [nzAvatar]="invoicesIcon"
              [nzTitle]="comingSoonFeatures.includes('invoices') ? 'Invoices (coming soon)' : 'Invoices'"
              nzDescription="Explore detailed information about your organization's invoices.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="loggedInUser.attributes.type == 'Ticketing::Agent'"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('invoices') ? [] : ['/app', 'services-audit']">
        <nz-card nzBorderless="true" class="card agent-solution" [ngClass]="{'coming-soon': comingSoonFeatures.includes('services-audit')}">
          <nz-card-meta
              [nzAvatar]="servicesAuditIcon"
              [nzTitle]="comingSoonFeatures.includes('services-audit') ? 'Services Audit (coming soon)' : 'Services Audit'"
              nzDescription="Explore details on our monthly customer services/licenses billing.">
          </nz-card-meta>
        </nz-card>
      </div>

      @if (['account_admin_contact_role', 'admin_contact_role', 'billing_contact_role', 'account_agent_role'].includes(userRoleKey)) {
        <div nz-col
             [nzXs]="{ span: 24 }"
             [nzSm]="{ span: 24 }"
             [nzMd]="{ span: 12 }"
             [routerLink]="['/app', 'account-management']">
          <nz-card nzBorderless="true" class="card">
            <nz-card-meta [nzAvatar]="accountManagementIcon"
                          nzTitle="Account Management"
                          nzDescription="We provide you a one-click access to the most frequently used features.">
            </nz-card-meta>
          </nz-card>
        </div>
      }

      @if (meetingsPermission && ['Digcore::AccountAgent', 'Digcore::Contact'].includes(userType)) {
        <div nz-col
             [nzXs]="{ span: 24 }"
             [nzSm]="{ span: 24 }"
             [nzMd]="{ span: 12 }"
             [routerLink]="['/app', 'meetings']">
          <nz-card nzBorderless="true" class="card">
            <nz-card-meta [nzAvatar]="meetingIcon"
                          nzTitle="Meetings"
                          nzDescription="You can create your meetings and view them in a list.">
            </nz-card-meta>
          </nz-card>
        </div>
      }

      <div *ngIf="assetResourcesPermission"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('assets') ? [] : ['/app', 'assets']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent',  'coming-soon': comingSoonFeatures.includes('assets')}">
          <nz-card-meta
              [nzAvatar]="assetsIcon"
              [nzTitle]="comingSoonFeatures.includes('assets') ? 'Assets (coming soon)' : 'Assets'"
              nzDescription="Explore all assets of your organization conveniently in one place.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="serviceHistoriesPermission"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('services-change-history') ? [] : ['/app', 'services-change-history']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent', 'coming-soon': comingSoonFeatures.includes('services-change-history')}">
          <nz-card-meta
              [nzAvatar]="servicesChangeHistoryIcon"
              [nzTitle]="comingSoonFeatures.includes('services-change-history') ? 'Service Change History (coming soon)' : 'Service Change History'"
              nzDescription="Find out what has changed in your services.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div nz-col
            [nzXs]="{ span: 24 }"
            [nzSm]="{ span: 24 }"
            [nzMd]="{ span: 12 }"
            [routerLink]="comingSoonFeatures.includes('news') ? [] : ['/app', 'news']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent', 'coming-soon': comingSoonFeatures.includes('news')}">
          <nz-card-meta
              [nzAvatar]="textFileIcon"
              [nzTitle]="comingSoonFeatures.includes('news') ? 'News (coming soon)' : 'News'"
              nzDescription="Get news or information directly from your organization in-platform.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="flowCreatePermission"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           [routerLink]="comingSoonFeatures.includes('flows') ? [] : ['/app', 'flows']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent', 'coming-soon': comingSoonFeatures.includes('flows')}">
          <nz-card-meta
              [nzAvatar]="flowsIcon"
              [nzTitle]="comingSoonFeatures.includes('flows') ? 'Flows (coming soon)' : 'Flows'"
              nzDescription="Create and manage the flows for EMPIST clients.">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="incidentIndexPermission && loggedInUser.attributes.type != 'Ticketing::Agent'"
            nz-col
            [nzXs]="{ span: 24 }"
            [nzSm]="{ span: 24 }"
            [nzMd]="{ span: 12 }"
            [routerLink]="comingSoonFeatures.includes('incidents') ? [] : ['/app', 'incidents']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'coming-soon': comingSoonFeatures.includes('incidents')}">
          <nz-card-meta
            [nzAvatar]="incidentsIcon"
            [nzTitle]="comingSoonFeatures.includes('incidents') ? 'Incidents (coming soon)' : 'Incidents'"
            nzDescription="View your organization incidents">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="changelogIndexPermission && loggedInUser.attributes.type != 'Ticketing::Agent'"
            nz-col
            [nzXs]="{ span: 24 }"
            [nzSm]="{ span: 24 }"
            [nzMd]="{ span: 12 }"
            [routerLink]="comingSoonFeatures.includes('changes') ? [] : ['/app', 'changes']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'coming-soon': comingSoonFeatures.includes('changes')}">
          <nz-card-meta
            [nzAvatar]="changelogsIcon"
            [nzTitle]="comingSoonFeatures.includes('changes') ? 'Changes (coming soon)' : 'Changes'"
            nzDescription="View your organization changes">
          </nz-card-meta>
        </nz-card>
      </div>

      <div *ngIf="usersPermission && ['account_admin_contact_role', 'admin_contact_role', 'billing_contact_role', 'account_agent_role'].includes(userRoleKey)"
            nz-col
            [nzXs]="{ span: 24 }"
            [nzSm]="{ span: 24 }"
            [nzMd]="{ span: 12 }"
            [routerLink]="comingSoonFeatures.includes('users') ? [] : ['/app', 'users']">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent', 'coming-soon': comingSoonFeatures.includes('users')}">
          <nz-card-meta
              [nzAvatar]="usersIcon"
              [nzTitle]="comingSoonFeatures.includes('users') ? 'User management (coming soon)' : 'User management'"
              nzDescription="Manage users effortlessly with features like user list, detailed single-user pages, editing options, and the ability to create new users.">
          </nz-card-meta>
        </nz-card>
      </div>

      <a *ngIf="loggedInUser.attributes.type != 'Ticketing::Agent'"
           nz-col
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }"
           href="https://projects.empist.com/"
           target="_blank">
        <nz-card nzBorderless="true" class="card" [ngClass]="{'agent-solution': loggedInUser.attributes.type == 'Ticketing::Agent'}">
          <nz-card-meta
              [nzAvatar]="projects"
              nzTitle="Projects">
          </nz-card-meta>
        </nz-card>
      </a>

      <div nz-col *ngFor="let i of []"
           [nzXs]="{ span: 24 }"
           [nzSm]="{ span: 24 }"
           [nzMd]="{ span: 12 }">
        <nz-card>
          <nz-skeleton [nzActive]="true" [nzLoading]="true" [nzAvatar]="{ size: 'large' }">
            <nz-card-meta
                [nzAvatar]="ellipsisIcon">
            </nz-card-meta>
          </nz-skeleton>
        </nz-card>
      </div>
    </div>
  </nz-content>
</nz-layout>

<ng-template #commentIcon>
  <i nz-icon nzType="comment" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #textFileIcon>
  <i nz-icon nzType="file-text" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #ellipsisIcon>
  <i nz-icon nzType="ellipsis" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #bookIcon>
  <i nz-icon nzType="book" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #assetsIcon>
  <i nz-icon nzType="database" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #billingIcon>
  <i nz-icon nzType="money-collect" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #servicesAuditIcon>
  <dw-icon type="dw:servicesAudit" class="card-icon anticon"></dw-icon>
</ng-template>
<ng-template #servicesIcon>
  <dw-icon type="dw:services" class="card-icon anticon"></dw-icon>
</ng-template>
<ng-template #invoicesIcon>
  <dw-icon type="dw:invoices" class="card-icon anticon"></dw-icon>
</ng-template>
<ng-template #manuallyManagedServicesIcon>
  <i nz-icon nzType="group" [nzTheme]="'outline'" class="card-icon"></i>
</ng-template>
<ng-template #flowsIcon>
  <i nz-icon nzType="partition" nzTheme="outline" class="card-icon"></i>
</ng-template>
<ng-template #servicesChangeHistoryIcon>
  <i nz-icon nzType="swap" nzTheme="outline" class="card-icon"></i>
</ng-template>
<ng-template #meetingIcon>
  <dw-icon type="dw:events" class="card-icon anticon"></dw-icon>
</ng-template>
<ng-template #accountManagementIcon>
  <dw-icon type="dw:accountMAnagement" class="card-icon anticon"></dw-icon>
</ng-template>
<ng-template #projects>
  <dw-icon type="dw:projects" class="card-icon anticon"></dw-icon>
</ng-template>
<ng-template #incidentsIcon>
  <i nz-icon nzType="warning" nzTheme="outline" class="card-icon"></i>
</ng-template>
<ng-template #usersIcon>
  <i nz-icon nzType="team" class="card-icon"></i>
</ng-template>
<ng-template #changelogsIcon>
  <i nz-icon nzType="interaction" nzTheme="outline" class="card-icon"></i>
</ng-template>
