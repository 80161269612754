import {ObjectData} from "../objectData";
import {IncidentAttributes} from "./incidentAttributes";
import {IncidentRelationships} from "./incidentRelationships";
import {IncidentStatusRelationAttributes, IncidentStatusRelationRelationships} from "./incidentStatusRelation";
import {IncidentSeverityRelationAttributes, IncidentSeverityRelationRelationships} from "./incidentSeverityRelation";
import {IncidentComponentRelationAttributes, IncidentComponentRelationRelationships} from "./incidentComponentRelation";
import {TicketRelationAttributes, TicketRelationRelationships} from "./ticketRelation";

export class Incident  extends ObjectData<IncidentAttributes, IncidentRelationships>{
  constructor(data: ObjectData<IncidentAttributes, IncidentRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = Incident.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): IncidentRelationships {
    let relationships = new IncidentRelationships();

    relationships.account = Incident.normalizeAccountRelation(relationObject, included);
    relationships.incident_status = Incident.normalizeSingleRelation<IncidentStatusRelationAttributes, IncidentStatusRelationRelationships>(relationObject, included, 'incident_status');
    relationships.incident_severity = Incident.normalizeSingleRelation<IncidentSeverityRelationAttributes, IncidentSeverityRelationRelationships>(relationObject, included, 'incident_severity');
    relationships.incident_component = Incident.normalizeSingleRelation<IncidentComponentRelationAttributes, IncidentComponentRelationRelationships>(relationObject, included, 'incident_component');
    relationships.requester = Incident.normalizeUserRelation(relationObject, included, 'requester');
    relationships.creator = Incident.normalizeUserRelation(relationObject, included, 'creator');
    relationships.tickets = Incident.normalizeMultipleRelation<TicketRelationAttributes, TicketRelationRelationships>(relationObject, included, 'tickets');
    relationships.resolved_by = Incident.normalizeUserRelation(relationObject, included, 'resolved_by');

    return relationships;
  }
}
