{
  "data": [
    {
      "id": 1,
      "attributes": {
        "model_attributes": [
          {
            "key": "fullname",
            "type": "string",
            "label": "Fullname",
            "available_as_filter": [
              "user_list"
            ],
            "available_as_column": [
              "user_list"
            ]
          },
          {
            "key": "email",
            "type": "string",
            "label": "Email",
            "sort": "email",
            "available_as_filter": [
              "user_list"
            ],
            "available_as_column": [
              "user_list"
            ]
          },
          {
            "key": "active",
            "type": "boolean",
            "label": "Active",
            "sort": "active",
            "available_as_filter": [
              "user_list"
            ],
            "available_as_column": [
              "user_list"
            ]
          }
        ],
        "model_associations": [
          {
            "name": "price_tiers",
            "mapping_name": "price_tier",
            "label": "Type",
            "available_as_filter": [
              "user_list"
            ],
            "available_as_column": [
              "user_list"
            ],
            "attributes": [
              {
                "key": "id",
                "type": "select",
                "label": "Title",
                "source": "API",
                "action": "price_tiers",
                "sort": "title",
                "available_as_filter": [
                  "user_list"
                ],
                "available_as_column": []
              },
              {
                "key": "title",
                "type": "string",
                "label": "Title",
                "available_as_filter": [],
                "available_as_column": [
                  "user_list"
                ]
              }
            ]
          }
        ],
        "model_custom_attributes": []
      },
      "type": "user_available_filters"
    }
  ],
  "link": {},
  "meta": {}
}