import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, EMPTY, Subject, forkJoin, map, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { TokenService } from "src/app/auth/token.service";
import { Permission } from "src/app/utilities/models/permissions/permission";
import { User } from "src/app/utilities/models/user/user";
import { Status } from "src/app/utilities/models/lists/status";
import { Priority } from "src/app/utilities/models/lists/priority";
import { RequestType } from "src/app/utilities/models/lists/requestType";
import { IssueType } from "src/app/utilities/models/lists/issueType";
import { UserUpdateDto } from "src/app/utilities/models/dto/userUpdateDto";
import { disableTfaDto } from "src/app/utilities/models/dto/disableTfaDto";
import { enableTfaDto } from "src/app/utilities/models/dto/enableTfaDto";
import { ReportParam } from "src/app/utilities/models/parameters/reportParam/reportParam";
import { AccountReviewUsersDto } from "src/app/utilities/models/dto/accountReviewUsersDto";
import { ArrayHelper } from "src/app/utilities/helpers/arrayHelpers";
import { UserCreateDto } from "../utilities/models/dto/userCreateDto";

@Injectable({ providedIn: "root" })
export class UsersService {
  permissionsArray: Permission[] = [];
  statusArray: Status[] = [];
  priorityArray: Priority[] = [];
  requestTypeArray: RequestType[] = [];
  issueTypeArray: IssueType[] = [];
  loggedInUser: User;
  userSubject = new Subject<User>();
  accountManager = new BehaviorSubject<User | undefined>(undefined);
  showPasswordModal: boolean = false;
  fixedLists: any;
  prefDarkMode = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private tokenService: TokenService) {
  }

  fetchUser() {
    const token = this.tokenService.getDecodedToken();
    const userId = token?.user.id;
    const profileUrl = environment.api_url + 'operator/v1/users/' + userId + '/profile';
    let urlParams = {
      include: "user_panel,role,account"
    }

    return this.http.get(profileUrl, { params: urlParams }).pipe(
      tap({
        next: (response: any) => {
          this.loggedInUser = new User(response.data, response.included);
          this.userSubject.next(this.loggedInUser);
          this.prefDarkMode.next(this.loggedInUser?.attributes?.pref_dark_mode ?? false);
        }
      })
    )
  }

  getProvisionURL() {
    const requestURL = `${environment.api_url}operator/v1/users/${this.loggedInUser.id}/enable_tfa`;

    return this.http.get(requestURL);
  }

  disableTFA(payload: disableTfaDto){
    const requestURL = `${environment.api_url}operator/v1/users/${this.loggedInUser.id}/`;

    return this.http.patch(requestURL, payload);
  }

  enableTFA(payload: enableTfaDto){
    const tfaUrl = `${environment.api_url}operator/v1/users/${this.loggedInUser.id}/verify_enable_tfa`;

    return this.http.post(tfaUrl, payload);
  }

  fetchPermissions() {
    const permissionsUrl = environment.api_url + 'operator/v1/users/permissions';
    this.permissionsArray = [];

    return this.http.get(permissionsUrl).pipe(
      tap({
        next: (response: any) => {
          const permissionResponseArray = Object.values<Permission>(response.data[0].attributes.message);
          permissionResponseArray.map(permission => this.permissionsArray.push(permission));
        }
      })
    );
  }

  private fetchLists() {
    let userBase = '';
    switch(this.loggedInUser.attributes.type){
      case 'Digcore::Contact': userBase = 'contacts'; break;
      case 'Digcore::AccountAgent': userBase = 'account_agents'; break;
      case 'Ticketing::Agent': userBase = 'agents'; break;
    }
    const listsUrl = environment.api_url + 'operator/v1/' + userBase + '/fixed_lists';

    return this.http.get(listsUrl).pipe(
      tap({
        next: (response: any) => {
          const statusResponseArray = Object.values<Status>(response.data[0].attributes.statuses);
          const priorityResponseArray = Object.values<Priority>(response.data[0].attributes.priorities);
          const requestTypeResponseArray = Object.values<RequestType>(response.data[0].attributes.request_types);
          const issueTypeResponseArray = Object.values<IssueType>(response.data[0].attributes.issue_types);

          statusResponseArray.map(status => this.statusArray.push(status));
          priorityResponseArray.map(priority => this.priorityArray.push(priority));
          requestTypeResponseArray.map(request_type => this.requestTypeArray.push(request_type));
          issueTypeResponseArray.map(issue_type => this.issueTypeArray.push(issue_type));

          this.fixedLists = response.data[0].attributes;
        }
      })
    )
  }

  findPermission(model: string, controller: string, action: string): Permission | undefined {
    return this.permissionsArray.find(perm => perm.associated_model === model && perm.associated_controller === controller && perm.associated_action === action);
  }

  private havePermission(): boolean {
    return (this.permissionsArray.length > 0);
  }

  private haveUser(): boolean {
    return (this.loggedInUser != undefined)
  }

  isAppReady(): boolean {
    return (this.haveUser() && this.havePermission());
  }

  prepareApp() {
    return this.fetchUser().pipe(
      switchMap(
        _data => this.fetchPermissions().pipe(
          switchMap(
            _data => this.fetchLists()
          )
        )
      )
    );
  }

  createUser(payload: UserCreateDto) {
    const requestUrl = `${environment.api_url}operator/v1/${payload.type}`;

    return this.http.post<any>(requestUrl, payload);
  }

  getUser(userId: number, endpoint: string = 'users') {
    const requestUrl = `${environment.api_url}operator/v1/${endpoint}/${userId}`;

    const urlParams = {
      include: 'account,authentication_provider,extra_emails,price_tier,role'
    }

    return this.http.get<any>(requestUrl, { params: urlParams });
  }

  updateUser(payload: UserUpdateDto, endpoint: string = 'users') {
    const requestUrl = `${environment.api_url}operator/v1/${endpoint}/${payload.data.id}`;

    return this.http.patch<any>(requestUrl, payload);
  }

  getCurrentUser() {
    const requestUrl = environment.api_url + 'operator/v1/users/' + this.loggedInUser.id + '/profile';
    let urlParams = {
      include: "user_panel,role,account"
    }

    return this.http.get<any>(requestUrl, { params: urlParams }).subscribe({
      next: (response) => {
        this.loggedInUser = new User(response.data, response.included);
        this.userSubject.next(this.loggedInUser);
        this.prefDarkMode.next(this.loggedInUser?.attributes?.pref_dark_mode ?? false);
      }
    });
  }

  getAccountManager() {
    const requestUrl = environment.api_url + 'operator/v1/users/my_account_manager?include=user_panel';

    return this.http.get<any>(requestUrl)
      .pipe(
        tap((response: any) => {
          const accountManager = new User(response.data, response.included);
          this.accountManager.next(accountManager);
        })
      );
  }

  setShowPasswordModal(): void {
    if (
      !this.tokenService.getRefreshToken() &&
      this.loggedInUser?.attributes?.password_updated_at == null
    )
    {
      this.showPasswordModal = true;
    }
    // TODO check if password was changed a long time ago (force password update)
  }

  getAccountUsers({
    reportParams = [],
    pageSize = 10000,
    pageIndex = 1,
    include = 'price_tier,role,user_panel',
    sort = {},
  }: {
    reportParams?: ReportParam[],
    pageIndex?: number
    pageSize?: number
    include?: string,
    sort?: {
      attribute?: string,
      order?: string,
    }
  }) {
    let requestParams: any = {
      include,
      "query_type": 'documents',
      "page[per]": pageSize,
      "page[number]": pageIndex,
      "w[report_params]": JSON.stringify(reportParams),
    }

    if (sort?.attribute && sort?.order) {
      requestParams = {
        ...requestParams,
        [`s[${sort.attribute}]`]: sort.order
      }
    }

    return this.http.get(`${environment.api_url}operator/v1/users`, {
      params: requestParams
    });
  }

  getAccountUsersDocumentCount(reportParams: ReportParam[]) {
    const requestUrl = environment.api_url + 'operator/v1/users';
    let requestParams = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "query_type": 'documents_count'
    }

    return this.http.get(requestUrl,{ params: requestParams });
  }

  getManagedUsersPerAccount(reportParams: ReportParam[]) {
    const requestUrl = environment.api_url + 'operator/v1/users';
    let requestParams = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "aggregations": '["accounts"]',
      "query_type": "aggregations",
    }

    return this.http.get(requestUrl,{ params: requestParams });
  }

  getFixedLists() {
    const userBase = this.loggedInUser.attributes.type == 'Digcore::Contact' ? 'contacts' : 'agents'
    const listsUrl = environment.api_url + 'operator/v1/' + userBase + '/fixed_lists';

    return this.http.get(listsUrl).pipe(
      tap({
        next: (response: any) => {

          return response;
        }
      })
    )
  }

  setAccountUsersAsReviewed(payload: AccountReviewUsersDto) {
    const requestUrl = environment.api_url + 'operator/v1/accounts/' + payload.data.id + '/review_users';

    return this.http.post(requestUrl, payload);
  }

  getAccountManagedUsersIncludeDeleted(accountId: number) {
    let reportArr: ReportParam[] = [];
    reportArr.push({ "key": "accounts.id", "operator": ["eq"], "value": [accountId] });
    reportArr.push({ "key": "active", "operator": ["eq"], "value": "true" });

    const deletedParams: ReportParam[] = [];
    deletedParams.push({ "key": "accounts.id", "operator": ["eq"], "value": [accountId] });
    deletedParams.push({ "key": "reviewed", "operator": ["eq"], "value": "false" });
    deletedParams.push({ "key": "deleted_at", "operator": ["exist"],  "value": "-" });
    deletedParams.push({ "key": "active", "operator": ["eq"], "value": "false" });

    return forkJoin([
        this.getAccountUsers({ reportParams: reportArr }),
        this.getAccountUsers({ reportParams: deletedParams })
      ])
      .pipe(
        map(([response1, response2]: any) => {
          const included = ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? []);
          return {
            data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id'])
              .map((row: any) => {
                const user = new User(row, included);

                return {
                  id: user.id,
                  type: user.type,
                  attributes: {
                    col1: user.attributes.firstname,
                    col2: user.attributes.lastname,
                    col3: user.attributes.email,
                    col4: user.attributes.created_at,
                    col5: user.attributes.last_login ?? undefined,
                    col6: user.relationships?.price_tier?.attributes.title ?? undefined,
                    reviewed: user.attributes.reviewed,
                    deleted_at: user.attributes?.deleted_at ?? undefined,
                    mark_for_deletion: user.attributes.mark_for_deletion,
                  }
                }
              }),
            included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
            meta: {
              total_count: response1.meta.total_count + response2.meta.total_count,
              total_pages: 1,
              current_page: 1,
            }
          }
        })
      )
  }

  getPriceTiers() {
    const requestUrl = environment.api_url + 'operator/v1/price_tiers';
    let requestParams = {
      "q[active_eq]": true
    }

    return this.http.get(requestUrl,{ params: requestParams });
  }

  getRoles() {
    const requestUrl = environment.api_url + 'operator/v1/roles';
    let requestParams = {
      "q[active_eq]": true,
      "q[user_type_eq]": 'Digcore::Contact',
    }

    return this.http.get(requestUrl,{ params: requestParams });
  }

  getUserByIds(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/users?include=price_tier';
    const reportParams = [
      {key: 'id', operator: ['in'], value: ids}
    ]
    let requestParams: { [key: string]: string | number | number[] } = {
      "w[report_params]": JSON.stringify(reportParams).replace(/\\"/g, '"'),
      "page[per]": 10000,
      "page[number]": 1,
      "query_type": "documents",
    };

    return this.http.get(requestUrl, { params: requestParams });
  }

  getUserByIdsIncludeDeleted(ids: number[]) {
    const requestUrl = environment.api_url + 'operator/v1/users?include=price_tier';
    const reportParams1 = [
      {key: 'id', operator: ['in'], value: ids},
    ]
    const reportParams2 = [
      {key: 'id', operator: ['in'], value: ids},
      {key: "deleted_at", operator: ["exist"], value: "-"}
    ]
    let requestParams1: { [key: string]: string | number | number[] } = {
      "w[report_params]": JSON.stringify(reportParams1).replace(/\\"/g, '"'),
      "page[per]": 10000,
      "page[number]": 1,
      "query_type": "documents",
    };
    let requestParams2: { [key: string]: string | number | number[] } = {
      "w[report_params]": JSON.stringify(reportParams2).replace(/\\"/g, '"'),
      "page[per]": 10000,
      "page[number]": 1,
      "query_type": "documents",
    };

    return forkJoin([
      this.http.get(requestUrl, { params: requestParams1 }),
      this.http.get(requestUrl, { params: requestParams2 }),
    ])
    .pipe(
      map(([response1, response2]: any) => {
        return {
          data: ArrayHelper.mergeUnique(response1.data ?? [], response2.data ?? [], ['id']),
          included: ArrayHelper.mergeUnique(response1.included ?? [], response2.included ?? [], ['id','type']),
          meta: {
            total_count: response1.meta.total_count + response2.meta.total_count,
            total_pages: 1,
            current_page: 1,
          }
        }
      })
    );
  }

  getAvailableAgentsForTicket(ticketId: number) {
    const requestUrl = `${environment.api_url}operator/v1/agents/available_for_ticket?ticket_id=${ticketId}`;

    return this.http.get<any>(requestUrl);
  }

  setPrimaryEmail(userId: number | string, extraEmailId: number | string) {
    if (!userId || !extraEmailId) return EMPTY;

    const requestURL = `${environment.api_url}operator/v1/users/${userId}/swap_email`;
    const payload = {
      data: {
        attributes: {},
        relationships: {
          extra_emails: {
            data: {
              id: extraEmailId,
              type: 'extra_emails'
            }
          }
        }
      }
    }

    return this.http.patch(requestURL, payload);
  }

  getUserRelatedAggs(userId: number | string, userType: string) {
    if (!userId || !userType) return EMPTY;

    const requestURL = `${environment.api_url}operator/v1/${userType}/${userId}/related_aggs`;

    return this.http.get(requestURL);
  }
}
