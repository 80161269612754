import { ModelAssociationAttribute, ModelAttribute, ModelCustomAttribute } from "./availableFiltersResponse";
import { FormControl } from "@angular/forms";
import { FilterAggregationOption } from "./filterAggregationOption";
import DateHelper from "../../helpers/dateHelper";

export class AvailableFilterDetail {
  key: string;
  type: string;
  label: string;
  computed_key: string;
  computed_label: string;
  source?: string;
  action?: string;
  sort?: string;
  aggregation_key?: string;
  options?: any[];

  static getFormControlByType(type: string) {
    switch (type) {
      case 'boolean':
        return new FormControl<boolean>(true);
      case 'text':
      case 'string':
        return new FormControl<string>('');
      case 'select':
        return new FormControl<string[] | number[]>([]);
      case 'date':
        return new FormControl<string[]>([]);
      case 'simplified_date':
        return new FormControl<string>('');
      default:
        return new FormControl<string>('');
    }
  }

  static predefinedDateOptions(): Map<number, { label: string, months: number, operator: string[] }> {
    const options = new Map();

    options.set(1, { label: 'expired last 12 months', months: -12, operator: ['gte', 'lt'] });
    options.set(2, { label: 'expired last 4 months', months: -4, operator: ['gte', 'lt'] });
    options.set(3, { label: 'expired last month', months: -1, operator: ['gte', 'lt'] });
    options.set(4, { label: 'expires in one month', months: 1, operator: ['gte', 'lt'] });
    options.set(5, { label: 'expires in two months', months: 2, operator: ['gte', 'lt'] });
    options.set(6, { label: 'expires in three months', months: 3, operator: ['gte', 'lt'] });
    options.set(7, { label: 'expires in six months', months: 6, operator: ['gte', 'lt'] });
    options.set(8, { label: 'expires in one year', months: 12, operator: ['gte', 'lt'] });
    options.set(9, { label: 'is active', months: 0, operator: ['gte'] });
    options.set(10, { label: 'is expiring', months: 3, operator: ['lte'] });
    options.set(11, { label: 'is expired', months: 0, operator: ['lt'] });
    options.set(12, { label: 'is not set', months: 0, operator: ['empty'] });

    return options
  }

  constructor(data: ModelAssociationAttribute | ModelAttribute | ModelCustomAttribute, group: string, associationData?: any) {
    if (group == 'model_attributes') this.setDataFromAttributes(<ModelAttribute>data);
    if (group == 'model_association_attributes' && associationData) this.setDataFromAssociationAttributes(<ModelAssociationAttribute>data, associationData);
    if (group == 'model_custom_attributes') this.setDataFromCustomAttributes(<ModelCustomAttribute>data);
  }

  setDataFromAttributes(data: ModelAttribute) {
    this.computed_key = data.key;
    this.computed_label = data.label;
    this.key = data.key;
    this.type = data.type;
    this.label = data.label;

    if (data.key == 'warranty_expiration_date') {
      this.type = 'simplified_date';
    }

    if (data.options) {
      this.options = data.options;
    }
  }

  setDataFromAssociationAttributes(data: ModelAssociationAttribute, associationData: any) {
    this.key = data.key;
    this.type = data.type;
    this.label = data.label;
    this.action = data.action;
    this.source = data.source;
    this.sort = data.sort;

    this.aggregation_key = associationData.name;
    this.computed_key = associationData.name + '.' + this.key;
    this.computed_label = associationData.label + ' - ' + this.label;

    if (data.options) {
      this.options = data.options;
    }
  }

  setDataFromCustomAttributes(data: ModelCustomAttribute) {
    this.key = data.cf_key;
    this.type = data.cf_type;
    this.label = data.label;
    this.computed_key = data.cf_key;
    this.computed_label = data.label;
  }

  getOperatorByValue(value: any) {
    switch (this.type) {
      case 'text':
      case 'string':
        return ['like'];
      case 'select':
      case 'static_select':
        return value == 0 ? ['empty'] : ['in'];
      case 'date':
        return ['gte', 'lt'];
      case 'simplified_date':
        let res = AvailableFilterDetail.predefinedDateOptions().get(value)?.operator;
        return res ? res : [];
      default:
        return [];
    }
  }

  getValueByType(value: any) {
    if (this.type != 'simplified_date')
      return value;

    let dateOption = AvailableFilterDetail.predefinedDateOptions().get(value);
    if (dateOption) {
      switch (value) {
        case 1:
        case 2:
        case 3:
          return [`${DateHelper.dateAddMonths(new Date(), dateOption.months)}`, `${DateHelper.getFormattedDate(new Date())}`];
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          return [`${DateHelper.getFormattedDate(new Date())}`, `${DateHelper.dateAddMonths(new Date(), dateOption.months)}`];
        case 9:
          return [`${DateHelper.getFormattedDate(new Date())}`];
        case 10:
          return [`${DateHelper.dateAddMonths(new Date(), dateOption.months)}`];
        case 11:
          return [`${DateHelper.getFormattedDate(new Date())}`];
        default:
          return [];
      }
    }
  }

  static getDateOptions() {
    let response: FilterAggregationOption[] = [];

    AvailableFilterDetail.predefinedDateOptions().forEach((value, key) => {
      response.push({ value: key, label: value.label });
    });

    return response;
  }

}
