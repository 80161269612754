import {ChangelogUpdateAttributes} from "./changelogUpdateAttributes";
import {ChangelogUpdateRelationships} from "./changelogUpdateRelationships";
import {ObjectData} from "../objectData";

export class ChangelogUpdate extends ObjectData<ChangelogUpdateAttributes, ChangelogUpdateRelationships>{
  constructor(data: ObjectData<ChangelogUpdateAttributes, ChangelogUpdateRelationships>, included: Object[]) {
    super(data, included);
    if (data.relationships && included) {
      this.relationships = ChangelogUpdate.normalizeRelation(data.relationships, included);
    }
  }

  private static normalizeRelation(relationObject: any, included: Object[]): ChangelogUpdateRelationships {
    let relationships = new ChangelogUpdateRelationships();

    relationships.user = ChangelogUpdate.normalizeUserRelation(relationObject, included, 'user');

    return relationships;
  }
}
